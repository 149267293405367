import { Component, Inject, OnInit } from '@angular/core';
import { UiConfig } from '@models/config';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
} from '@angular/material/dialog';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { RateCardService } from '@services/rate-card/rate-card.service';
import { EditableSegment, SegmentPopupProps } from '@models/rate-card/rate-card';

@Component({
    selector: 'app-edit-segment-popup',
    templateUrl: './edit-segment-popup.component.html',
    styleUrls: ['./edit-segment-popup.component.scss'],
    standalone: false,
})
export class EditSegmentPopupComponent implements OnInit {
    config: UiConfig;
    segmentData: EditableSegment;

    constructor(
      public dialogRef: MatDialogRef<EditSegmentPopupComponent>,
      protected rateCardSvc: RateCardService,
      protected lookupSvc: LookupV2Service,
      @Inject(MAT_DIALOG_DATA) public data: SegmentPopupProps,
    ) {}

    ngOnInit(): void {

        this.rateCardSvc.getEditableSegment(this.data.channelId, this.data.appMetricValueIds[0])
            .subscribe((segmentData: EditableSegment) => {
                this.segmentData = segmentData;
            });

    }

    close() {
        this.dialogRef.close();
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterTypes, NumberFilter } from '@models/filter-types';

@Pipe({
    name: 'isNumberFilter',
    standalone: false,
})
export class IsNumberFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is NumberFilter {
        return value?.Type === FilterTypes.NumberFilterType;
    }

}

<app-dialog-container class="dialog-wrapper dialog-station-exceptions" [title]="'Station Exceptions'" (closeEvent)="closeWindow()" [loading]="loading">
    <div class="station-exceptions">
        <label>Station Exceptions</label>
        <i class="fas fa-fw fa-warning station-exceptions-popup-icon" aria-hidden="true"></i>
    </div>
    <div>
        <label>Data for the below stations are not current. All rates and data for these stations reflect the dates below:</label>
    </div>
    <ag-grid-angular class="ag-theme-material station-exceptions-grid   "
        [rowData]="rows"
        [columnDefs]="colDefs"
        (gridReady)="onGridReadyAutoSize($event)"
        (gridColumnsChanged)="gridApi?.autoSizeAllColumns()"
        [domLayout]="'autoHeight'"
        domLayout="autoHeight"
    >
    </ag-grid-angular>
</app-dialog-container>


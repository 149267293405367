import { Pipe, PipeTransform } from '@angular/core';
import { Filter, MultiSelectFilter } from '@models/filter-types';
import { isMultiSelectFilter } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'isMultiselectFilter',
    standalone: false,
})
export class IsMultiselectFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is MultiSelectFilter<unknown> {
        return isMultiSelectFilter(value);
    }

}

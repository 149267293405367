import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterTypes, RadioFilter } from '@models/filter-types';

@Pipe({
    name: 'isRadioFilter',
    standalone: false,
})
export class IsRadioFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is RadioFilter<unknown> {
        return value?.Type === FilterTypes.RadioFilterType;
    }

}

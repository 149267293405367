<app-dialog-container [loading]="false" (closeEvent)="closeDialog()">
    <ag-grid-angular class="ag-theme-material info-popup-grid"
                     [rowData]="rows"
                     [columnDefs]="colDefs"
                     (gridReady)="onGridReadyAutoSize($event)"
                     (gridColumnsChanged)="gridApi?.autoSizeAllColumns()"
                     [suppressColumnVirtualisation]="true"
                     [stopEditingWhenCellsLoseFocus]="true"
                     [suppressContextMenu]="true"
    ></ag-grid-angular>
</app-dialog-container>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Filter } from '@models/filter-types';

@Component({
    selector: 'app-filter-widget',
    templateUrl: './filter-widget.component.html',
    styleUrls: ['./filter-widget.component.scss'],
    standalone: false,
})
export class FilterWidgetComponent {

    constructor(
        private dialogRef: MatDialogRef<FilterWidgetComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {filter: Filter<unknown>; buttonText: string},
    ) {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    validateAndClose() {
        if (this.data.filter.isValid) {
            this.dialogRef.close(this.data.filter.Value);
        }
    }
}

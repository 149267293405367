import { Pipe, PipeTransform } from '@angular/core';
import { TimeFilter, Filter, FilterTypes } from '@models/filter-types';

@Pipe({
    name: 'isTimeFilter',
    standalone: false,
})
export class IsTimeFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is TimeFilter {
        return value?.Type === FilterTypes.TimeFilterType;
    }

}

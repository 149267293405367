import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { Auth0UserProfile } from 'auth0-js';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { ConfigService } from '@services/config/config.service';
import { UiConfig } from '@models/config';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { PushNotificationService } from '@services/push-notification/push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    expiresIn: BehaviorSubject<number> = new BehaviorSubject(null);

    private protocol = window.location.hostname === 'localhost' ? 'http' : 'https';
    private auth0 = new auth0.WebAuth({
        ...environment.auth,
        redirectUri: `${this.protocol}://${window.location.hostname}:${window.location.port}/login/complete`,
    });
    private timer = null;


    constructor(private router: Router, private configSvc: ConfigService, private pushNotificationSvc: PushNotificationService) {
        this.parseToken(this.getAccessToken());
        // ensure the timer runs even if the user has refreshed the page
        this.startTimer();
    }

    public beginLogin(landing: string = UrlStore.ui.landing, customer?: string): void {
        // the landing page must be stored in local storage since we will be redirecting
        // to auth0 for login and variables in memory will be wiped
        localStorage.setItem('landing', landing);
        if (customer) {
            this.auth0.authorize({ state: customer });
        } else {
            this.auth0.authorize();
        }
    }

    public async completeLogin() {
        this.auth0.parseHash((err, result) => {
            if (err) {
                console.error(err);
                this.router.navigate([UrlStore.ui.loginError]);
                return;
            }
            this.auth0.client.userInfo(result.accessToken, async (e, info) => {
                if (e) {
                    console.error(e);
                } else {
                    this.parseToken(result.accessToken);
                    this.storeAuth(result, info);
                    const customerIds = this.getCustomerIds();
                    if (!customerIds.customerId && customerIds.allowedIds.length > 1) {
                        this.router.navigate([UrlStore.ui.switchCustomer]);
                        return;
                    }
                    this.pushNotificationSvc.subscribeToNotification();

                    forkJoin([this.loadConfig(), this.configSvc.getZendeskToken()])
                        .subscribe(
                            ([config, token]) => {
                                this.storeConfig(config);
                                this.storeZendeskToken(token.zendeskJwt);
                                this.createFullStoryScript();
                                this.createAuthScript();
                                this.createWidgetScript();
                                this.createSegmentScript();

                                const landing = localStorage.getItem('landing') || UrlStore.ui.landing;
                                this.router.navigate([landing]);
                                this.startTimer();
                            },
                            error => {
                                console.error(error);
                                if (error.status === 404) {
                                    this.router.navigate([UrlStore.ui.loginUserNotConfigured]);
                                } else {
                                    this.router.navigate([UrlStore.ui.loginError]);
                                }
                            },
                        );
                }
            });
        });
    }

    public createAuthScript() {
        const authScript = document.createElement('script');
        authScript.appendChild(document.createTextNode(
            `window.zESettings = {authenticate: { jwt: localStorage.getItem("zendeskJwt") }};
                var hideWidget = () => zE('webWidget', 'hide');
                var awaitZE = () => {
                    if (zE) {
                        zE('webWidget:on', 'close', function () {
                            zE("webWidget", "hide");
                        });
                        zE('webWidget:on', 'userEvent', function (event) {
                            analytics.track(event.action);
                        });
                        hideWidget();
                    }
                    else {
                        window.setTimeout(awaitZE, 100)
                    }
                }
                zE = null;
                awaitZE();
                `,
        ));
        authScript.setAttribute('id', 'zendesk');
        document.head.appendChild(authScript);
    }

    public createFullStoryScript() {
        const fullStoryScript = document.createElement('script');
        fullStoryScript.appendChild(document.createTextNode(
            /* eslint-disable max-len */
            `FS.identify(btoa(localStorage.getItem('email')), {
                displayName: JSON.parse(localStorage.getItem('userConfig')) ? JSON.parse(localStorage.getItem('userConfig')).userName : 'Unknown User',
                email: localStorage.getItem('email'),
            });`,
            /* eslint-enable max-len */
        ));
        document.head.appendChild(fullStoryScript);
    }

    public createWidgetScript() {
        const widgetScript = document.createElement('script');
        widgetScript.setAttribute(
            'src', 'https://static.zdassets.com/ekr/snippet.js?key=21fdda2f-a153-4d2e-b1bd-83a85a840a4e',
        );
        widgetScript.setAttribute('id', 'ze-snippet');
        document.head.appendChild(widgetScript);
    }

    public createGoogleAnalyticsScript() {
        const googleAnalyticsScript = document.createElement('script');
        googleAnalyticsScript.appendChild(document.createTextNode(
            `window.dataLayer.push({
                    'customerId': JSON.parse(window.localStorage.getItem('userConfig')).customerId,
                    'email': window.localStorage.getItem('email'),
                    'userRole': JSON.parse(window.localStorage.getItem('userConfig')).userRole
                });`,
        ));
        document.head.appendChild(googleAnalyticsScript);
    }

    public createSegmentScript() {
        const segmentScript = document.createElement('script');
        segmentScript.appendChild(document.createTextNode(
            `const userConfig = JSON.parse(localStorage.getItem('userConfig'));
             const rateOpticsUserConfig = userConfig?.roleConfig?.rate_optics || {};
             var rateOpticsCapabilities = {}
             for (const module in rateOpticsUserConfig) {
                for (const capability in rateOpticsUserConfig[module]) {
                    const access_label = module + '_' + capability
                    rateOpticsCapabilities[access_label] = rateOpticsUserConfig[module][capability]
                }
             }
             analytics.identify(
                 btoa(localStorage.getItem('email')), Object.assign({}, {
                 email: localStorage.getItem('email'),
                 name: userConfig.userName || 'Unknown User',
                 role: userConfig.userRole  || 'Unknown Role',
                 company: {"id": JSON.parse(localStorage.getItem('userConfig')) ? JSON.parse(localStorage.getItem('userConfig')).customerId : 'Unknown Customer'},
                 product: 'rateoptics',
                 market: userConfig.market,
                 station: userConfig.station,
                 markets: userConfig.markets,
                 stations: userConfig.stations,
                 exclude_from_reporting: userConfig.excludeFromReporting,
                 email_reports_enabled: userConfig.emailReportsEnabled,
                 account_executive: userConfig.accountExecutive,
                 active: true, // this is not correct, may need to be calculated?
                 std_role: userConfig.stdRole,
                 landing_page: userConfig.landingPage,
                 salesforce_account_id: userConfig.customerConfig.salesforce_account_id
             }, rateOpticsCapabilities));
             analytics.group(userConfig.customerId);`,
        ));
        document.head.appendChild(segmentScript);
    }

    public clearLocalStorage(): void {
        const removeKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const persistentValues = Object.values(UrlStore.localStorageKeys);
            if (!persistentValues.includes(key)) {
                removeKeys.push(key);
            }
        }
        removeKeys.forEach(key => localStorage.removeItem(key));
    }

    public clearROKeys(): void {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (Object.values(UrlStore.localStorageKeys).includes(key)) {
                localStorage.removeItem(key);
            }
        }
    }

    public logout() {
        this.clearLocalStorage();
        this.auth0.logout({
            returnTo: `${this.protocol}://${window.location.hostname}:${window.location.port}`,
        });
    }

    public isLoggedIn(): boolean {
        const token = localStorage.getItem('id_token');
        const expires = +localStorage.getItem('expires_at');

        return token && expires > new Date().getTime();
    }

    public isConfigured(): boolean {
        const config = JSON.parse(localStorage.getItem('userConfig')) as UiConfig;
        return config?.roleCapability?.rate_optics.dashboard.revenue_category !== undefined;
    }

    public isRaUser(): boolean {
        const email = localStorage.getItem('email');
        return email?.includes('@rateoptics.io') || email?.includes('@revenueanalytics.com');
    }


    public getAccessToken(): string {
        return localStorage.getItem('access_token');
    }

    public getExpiresIn(): number {
        const expires = +localStorage.getItem('expires_at');
        return Math.max((expires - new Date().getTime()) / 1000, 0);
    }

    public getConfig(): UiConfig {
        return JSON.parse(localStorage.getItem('userConfig'));
    }

    public loadConfig(): Observable<UiConfig> {
        return this.configSvc.getConfig();
    }

    public storeConfig(config: UiConfig): void {
        localStorage.setItem('userConfig', JSON.stringify(config));
    }

    public storeZendeskToken(token: string): void {
        localStorage.setItem('zendeskJwt', token);
    }

    public resetChameleonLocalVariables() {
        const chameleonKeys = ['ajs_anonymous_id', 'ajs_group_id', 'ajs_group_properties', 'ajs_user_id', 'ajs_user_traits', 'chmln:l.C.d'];

        for (const key of chameleonKeys) {
            localStorage.removeItem(key);
        }
    }
    // TEST-THIS
    getCustomerIds(): { customerId: string; allowedIds: string[] } {
        const decoded = jwtDecode(this.getAccessToken());
        const host = ['uat.rateoptics.io', 'rateoptics.io'].includes(window.location.hostname) ? 'media' : 'media-dev';
        return {
            customerId: decoded[`https://${host}.revenueanalytics.io/customerId`],
            allowedIds: decoded[`https://${host}.revenueanalytics.io/allowedIds`].split(','),
        };
    }

    private startTimer() {
        // setup a timer to check for expiring tokens and log the user out accordingly
        this.clearTimer();
        let timeLeft = this.getExpiresIn();

        if (!timeLeft) {
            return;
        }

        this.timer = setInterval(() => {
            timeLeft = this.getExpiresIn();
            this.expiresIn.next(timeLeft);
            if (timeLeft <= 0) {
                this.clearTimer();
                this.logout();
            }
        }, 500);
    }

    private clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    private storeAuth(authResult, info: Auth0UserProfile) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('email', info.email.toLowerCase());
        localStorage.setItem('picture', info.picture);
    }

    private parseToken(token: string) {
        if (!token) {
            return;
        }
    }
}

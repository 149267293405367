import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [
        trigger('toggleFilters', [
            state('open', style({ width: '19em' })),
            state('closed', style({ width: '1em' })),
            transition('open => closed', [animate('0.2s')]),
            transition('closed => open', [animate('0.2s')]),
            state('contentOn', style({ display: 'block' })),
            state('contentOff', style({ display: 'none' })),
            transition('contentOn => contentOff', [animate('0.2s')]),
            transition('contentOff => contentOn', [animate('0.2s')]),
        ]),
    ],
    standalone: false,
})
export class SidenavComponent {
    showSidenav = true;

    toggleSideNav(): void {
        this.showSidenav = !this.showSidenav;
    }

}

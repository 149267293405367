import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ViewChild,
} from '@angular/core';
import { PopupCellParams } from '@models/ag-grid';
import { EditSegmentNameParams, SegmentPopupProps, SystemSegmentPopupProps } from '@models/rate-card/rate-card';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AddSegmentPopupComponent } from '../../../../../rate-optics/rate-card/segmentation/add-segment-popup/add-segment-popup.component';
import tippy, { hideAll } from 'tippy.js';
import { RateCardMetricRowV2 } from '@models/rate-card/rate-card';
import { CONFIRM_DIALOG, ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import {
    EditSegmentPopupComponent,
} from '../../../../../rate-optics/rate-card/segmentation/edit-segment-popup/edit-segment-popup.component';
import {
    EditSystemSegmentPopupComponent,
} from '../../../../../rate-optics/rate-card/segmentation/edit-system-segment-popup/edit-system-segment-popup.component';


@Component({
    selector: 'app-popup-cell',
    templateUrl: './popup-cell.component.html',
    styleUrls: ['./popup-cell.component.scss'],
    standalone: false,
})
export class PopupCellComponent implements ICellRendererAngularComp, AfterViewInit {

    @ViewChild('content', { static: false }) container;

    @ViewChild('trigger') button;

    cdr: ChangeDetectorRef;

    isOpen = false;
    isPrimaryRate = false;
    isUserGenerated = false;

    params: PopupCellParams<RateCardMetricRowV2>;

    private tippyInstance;

    constructor() {
    }

    ngAfterViewInit(): void {
        if (this.button?.nativeElement) {
            this.tippyInstance = tippy(this.button.nativeElement);
            this.tippyInstance.disable();
        }
    }

    agInit(params: PopupCellParams<RateCardMetricRowV2>) {
        this.params = params;
        this.setIsPrimaryRate();
        this.isUserGenerated = this.params.isUserGenerated(this.params);
    }

    setIsPrimaryRate() {
        this.isPrimaryRate = this.params?.primaryRateValue?.Value.id === this.params?.data?.metricTypeId;
    }

    refresh() {
        this.setIsPrimaryRate();
        return true;
    }

    onClickHandler() {
        this.togglePopup();
    }

    hideSegment() {
        this.params.segmentButtonTrack('Remove Row');
        this.tippyInstance.hide();
        this.params.rateCardSvc.addHiddenSegment(
            this.params.data,
            this.params.rateCardTab,
            this.params.isDynamic,
            this.params.isPolitical,
            this.params.isDigital,
        ).subscribe(
            _ => {
                this.params.onRowRemoved(this.params);
            },
        );

    }

    openHideSegmentDialogForConfirmation() {
        const dialog = this.params.matDialog.open(ConfirmDialogComponent, {
            data: {
                text: 'Are you sure you want to remove this row from the Rate\n Card? This will remove it for all users',
                autoConfirm: false,
                noClickClose: true,
                confirmText: 'Remove Row',
                denyText: 'Cancel',
                denyClose: true,
            },
        });
        dialog.afterClosed().subscribe(result => {
            if (result === CONFIRM_DIALOG) {
                this.hideSegment();
            }
        });
    }

    openAddSegmentPopup() {
        this.params.segmentButtonTrack('Add Row');
        this.tippyInstance.hide();
        const channelId = this.params.data.channelId;
        const daypartId = this.params.data.daypartId;
        const spotTypeId = this.params.data.spotTypeId;
        this.params.matDialog.open(AddSegmentPopupComponent, {
            disableClose: true,
            width: '120em',
            data: {
                rateCardTab: this.params.rateCardTab,
                channelGroupId: this.params.channelGroupId,
                channelId,
                spotTypeId,
                daypartId,
                priceFloor: this.params.data.overridePriceFloor,
                priceMax: this.params.data.overridePriceMax,
                primarySpotLength: this.params.primarySpotLength,
                primaryTier: Object.values(
                    this.params.tiers?.[channelId]?.[daypartId]?.[spotTypeId] || {},
                ).find(tier => tier.isPrimary)?.displayName,
                isDynamic: this.params.isDynamic,
                isPolitical: this.params.isPolitical,
                parentDynamicSegmentation: this.params.data.segmentationIds,
                getRateCardSegments: this.params.getRateCardSegments,
                matDialog: this.params.matDialog,
                appMetricValueIds: this.params.appMetricValueIds(this.params),
                afterSave: (rows: RateCardMetricRowV2[]) => this.params.afterSave(this.params, rows, false),
                reloadParams: {
                    startDate: this.params.lastFilterGetter().startDate,
                    endDate: this.params.lastFilterGetter().endDate,
                    period: this.params.lastFilterGetter().period.id,
                },
            } as SegmentPopupProps,
        });
    }

    openEditSegmentPopup() {
        this.params.segmentButtonTrack('Edit Row');
        this.tippyInstance.hide();
        const channelId = this.params.data.channelId;
        const daypartId = this.params.data.daypartId;
        const spotTypeId = this.params.data.spotTypeId;
        if (this.isUserGenerated) {
            this.params.matDialog.open(EditSegmentPopupComponent, {
                disableClose: true,
                width: '120em',
                data: {
                    rateCardTab: this.params.rateCardTab,
                    channelGroupId: this.params.channelGroupId,
                    channelId,
                    spotTypeId,
                    daypartId,
                    priceFloor: this.params.data.overridePriceFloor,
                    priceMax: this.params.data.overridePriceMax,
                    primarySpotLength: this.params.primarySpotLength,
                    primaryTier: Object.values(
                        this.params.tiers?.[channelId]?.[daypartId]?.[spotTypeId] || {},
                    ).find(tier => tier.isPrimary)?.displayName,
                    isDynamic: this.params.isDynamic,
                    isPolitical: this.params.isPolitical,
                    getRateCardSegments: this.params.getRateCardSegments,
                    matDialog: this.params.matDialog,
                    appMetricValueIds: this.params.appMetricValueIds(this.params),
                    afterSave: (rows: RateCardMetricRowV2[]) => this.params.afterSave(this.params, rows, true),
                    reloadParams: {
                        startDate: this.params.lastFilterGetter().startDate,
                        endDate: this.params.lastFilterGetter().endDate,
                        period: this.params.lastFilterGetter().period.id,
                    },
                } as SegmentPopupProps,
            });
        } else {
            this.params.matDialog.open(EditSystemSegmentPopupComponent, {
                disableClose: true,
                data: {
                    channelId,
                    daypartId: this.params.data.daypartId,
                    segmentation: this.params.data.segmentationIds,
                    afterSave: (
                        segmentParams: EditSegmentNameParams[],
                        reloadRows: RateCardMetricRowV2[],
                    ) => this.params.editSegmentName(segmentParams, reloadRows),
                    getRateCardSegments: this.params.getRateCardSegments,
                    matDialog: this.params.matDialog,
                    baseSegmentationId: this.params.data.rateCardIdInt,
                    spotTypeId: this.params.data.spotTypeId,
                    reloadParams: {
                        startDate: this.params.lastFilterGetter().startDate,
                        endDate: this.params.lastFilterGetter().endDate,
                        period: this.params.lastFilterGetter().period.id,
                    },
                    rateCardTab: this.params.rateCardTab,
                } as SystemSegmentPopupProps,
            });
        }
    }

    configureTippyInstance() {
        this.tippyInstance.enable();
        this.tippyInstance.show();

        this.tippyInstance.setProps({
            trigger: 'manual',
            placement: 'right',
            arrow: false,
            interactive: true,
            appendTo: document.body,
            hideOnClick: false,
            onShow: (instance) => {
                hideAll({ exclude: instance });
            },
            onClickOutside: (instance, event) => {
                this.isOpen = false;
                instance.unmount();
            },
        });
    }

    togglePopup() {
        this.isOpen = !this.isOpen;
        setTimeout(() => {
            if (this.isOpen) {
                this.configureTippyInstance();
                this.tippyInstance.setContent(this.container.nativeElement);
            } else {
                this.tippyInstance.unmount();
            }
        });
    }
}

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BooleanFilter, NumberFilter, RadioFilter, SelectFilter, StringFilter } from '@models/filter-types';
import { LookupItem } from '@models/lookup';
import { FilterOption } from '@models/rate-card/rate-card';

@Component({
    selector: 'app-save-report-popup',
    templateUrl: './save-report-popup.component.html',
    styleUrls: ['./save-report-popup.component.scss'],
    standalone: false,
})
export class SaveReportPopupComponent implements OnInit {
    isLoading = false;
    filters: {
        newOrExistingFilter: RadioFilter<FilterOption>;
        selectExistingReportFilter: SelectFilter<LookupItem>;
        nameFilter: StringFilter;
        saveMetricSelectionsFilter: BooleanFilter;
        saveSegmentsSelectionsFilter: BooleanFilter;
        saveAsGlobalFilter: BooleanFilter;
        dateRangeOptionsFilter?: RadioFilter<FilterOption>;
        periodSelectFilter?: SelectFilter<LookupItem>;
        quickDateOptionsSelectFilter?: SelectFilter<LookupItem>;
        numberOfPeriodsFilter?: NumberFilter;
        addAsFavoriteFilter: BooleanFilter;
        showMetrics?: boolean;
    };
    allFiltersSelected: boolean;

    constructor(
        public dialogRef: MatDialogRef<SaveReportPopupComponent>,
        public changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data: {
            filters: {
                newOrExistingFilter: RadioFilter<FilterOption>;
                selectExistingReportFilter: SelectFilter<LookupItem>;
                nameFilter: StringFilter;
                saveMetricSelectionsFilter: BooleanFilter;
                saveSegmentsSelectionsFilter: BooleanFilter;
                saveAsGlobalFilter: BooleanFilter;
                dateRangeOptionsFilter?: RadioFilter<FilterOption>;
                periodSelectFilter?: SelectFilter<LookupItem>;
                quickDateOptionsSelectFilter?: SelectFilter<LookupItem>;
                numberOfPeriodsFilter?: NumberFilter;
                addAsFavoriteFilter: BooleanFilter;
                showMetrics?: boolean;
            };
            allFiltersSelectedCheck: boolean;
        },
    ) {
        this.filters = data.filters;
        this.allFiltersSelected = data.allFiltersSelectedCheck;
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    close() {
        let isValidName;
        let isValidPeriod;
        if (this.filters.newOrExistingFilter.Value.name === 'Create New') {
            this.filters.nameFilter.Validate();
            isValidName = this.filters.nameFilter.isValid;
        } else {
            isValidName = true;
        }
        if (this.filters?.dateRangeOptionsFilter &&
        this.filters.dateRangeOptionsFilter.Value.name === 'Save Rolling Dates' &&
        this.filters?.numberOfPeriodsFilter) {
            this.filters.numberOfPeriodsFilter.Validate();
            isValidPeriod = this.filters.numberOfPeriodsFilter.isValid;
        } else {
            isValidPeriod = true;
        }

        if(isValidName && isValidPeriod){
            this.dialogRef.close(this.filters);
        } else {
            console.log('There seems to be a problem with the report name or rolling period');
        }
    }
}

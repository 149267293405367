import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-copy-url-dialog',
    templateUrl: './copy-url-dialog.component.html',
    styleUrls: ['./copy-url-dialog.component.scss'],
    standalone: false,
})
export class CopyURLDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<CopyURLDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            url: string;
        },
    ) {}
    copyToClipboard(){
        navigator.clipboard.writeText(this.data.url).then(()=>this.dialogRef.close({ copied: true })).catch((err)=>console.error(err));
    }
    closeDialog() {
        this.dialogRef.close({ copied: false });
    }
}

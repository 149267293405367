<div *ngFor="let oneTimeFilter of oneTimeFilters;">
    <app-add-segment-popup-filter-row
                                      [template]="oneTimeFilter"
                                      [dynamicFilters]="oneTimeFilter.dynamicFilters">
    </app-add-segment-popup-filter-row>
</div>
<div *ngIf="canAddRow" class="level-left padding-75-em">
    <button class="button is-success" (click)="addRowOneTime()">
        Add Row
    </button>
</div>

    <div class="level is-full-width button-row button-container">
        <div class="level-left">
        </div>
        <div class="level-right">
            <button class="button level-item is-danger popup-button" (click)="close()">
                Cancel
            </button>
            <button class="button level-item is-primary popup-button" (click)="save()">
                Save
            </button>
        </div>
    </div>

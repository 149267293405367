import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { SelectFilterCellComponent } from
    '@shared/components/ag-grid-components/cell-renderers/select-filter-cell/select-filter-cell.component';
import { SelectFilterCellParams } from '@models/ag-grid';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { LookupItem } from '@models/lookup';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-select-filter-cell-editor',
    templateUrl: './select-filter-cell-editor.component.html',
    styleUrls: ['./select-filter-cell-editor.component.scss'],
    standalone: false,
})
export class SelectFilterCellEditorComponent extends SelectFilterCellComponent implements ICellEditorAngularComp, AfterViewChecked {
    editorOpened = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    agInit(params: SelectFilterCellParams): void {
        super.agInit(params);
        this.filter.OnChange((item: LookupItem) => {
            this.params.node.data[this.params.colDef.field] = item;
            if (this.params.changeFunction) {
                this.params.changeFunction(params.node.data, params.node, params.api);
            }
            this.params.api.refreshCells({ force: true });
            this.params.stopEditing();
        });

        this.filter.Readonly(this.filter.options?.length === 0);
    }

    getValue(): LookupItem {
        return this.filter.Value;
    }

    ngAfterViewChecked(): void {
        if (!this.editorOpened) {
            const popup = document.getElementsByClassName('ag-popup-editor').item(0);
            if (popup) {
                (popup as HTMLElement).focus();
                this.filterRef.open();
                this.editorOpened = true;
            }
        }
        this.changeDetectorRef.detectChanges();

    }

    isPopup(): boolean {
        return true;
    }

    refresh(params: ICellEditorParams | ICellRendererParams): boolean {
        return super.refresh(params as ICellRendererParams);
    }
}

<div>
    <mat-tab-group [preserveContent]="true" animationDuration="0ms" class="add-segment-popup">
        <mat-tab>
            <ng-template mat-tab-label>One Time Only</ng-template>
            <ng-template matTabContent>
                <app-one-time-segment
                    [rowData]="data"
                    [dialogRef]="dialogRef"
                    [isOneTimeSegment]="true"
                />
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>Recurring</ng-template>
            <ng-template matTabContent>
                <app-recurring-segment
                    [rowData]="data"
                    [dialogRef]="dialogRef"
                    [isOneTimeSegment]="false"
                />
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

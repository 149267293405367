<div *ngIf="!config">Loading <img src="assets/images/loader.svg" class="is-size-5"/></div>
<div *ngIf="config">
    <h1 class="is-flex justify-center">
        Edit {{ sortedStringSegments[sortedStringSegments.length - 1].segment_col_header }}</h1>
    <div *ngIf="!segments.length">Loading Segment Info <img src="assets/images/loader.svg" class="is-size-5"/></div>
    <div *ngIf="segments.length" class="is-flex">
        <div>
            <div *ngFor="let segment of segments">
                <h5>{{ segment.segmentHeader }}</h5>
                <div class="is-flex">
                    <app-filter-input [property]="segment.originalSegmentFilter"/>
                    <app-filter-input *ngFor="let segmentFilter of segment.newSegmentFilters" [property]="segmentFilter"/>
                </div>
            </div>
            <div>
                <h5>Hiatus Weeks</h5>
                <div class="is-flex">
                    <app-filter-input [property]="hiatusWeeksFilter" [disabledOptions]="inherentHiatusWeeks"/>
                </div>
            </div>
        </div>
        <div class="level is-full-width button-row button-container">
            <div class="level-left">
            </div>
            <div class="level-right">
                <button class="button level-item is-danger popup-button" (click)="close()">
                    Cancel
                </button>
                <button class="button level-item is-primary popup-button" (click)="save()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

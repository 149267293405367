import { AfterViewChecked, Component, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TextInputCellParams } from '@models/ag-grid';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { NumberFilter } from '@models/filter-types';
import { removeCommasFromValue } from '@shared/helpers/functions/helpers';

@Component({
    selector: 'app-text-input-cell-editor',
    templateUrl: './text-input-cell-editor.component.html',
    styleUrls: ['./text-input-cell-editor.component.scss'],
    standalone: false,
})
export class TextInputCellEditorComponent implements ICellEditorAngularComp, AfterViewChecked {
    @ViewChild('wrapper') wrapper: ElementRef;

    filter: NumberFilter;
    filterRef: ElementRef;
    params: TextInputCellParams;
    editorOpened = false;
    startingValue: number;
    clearable: boolean;
    revertable: boolean;


    constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }


    agInit(params: TextInputCellParams): void {
        this.params = params;
        this.startingValue = Number(this.params.eventKey) || params.startingValue(params);
        this.clearable = this.params.clearable(params);
        this.revertable = this.params.revertable(params);
        this.filter = new NumberFilter('')
            .DisplayKey(false)
            .Prefix(this.params.prefix ? this.params.prefix() : null)
            .Suffix(this.params.suffix ? this.params.suffix() : null)
            .FormatString(this.params.formatString ? this.params.formatString(this.params) : '1.0-0')
            .Default(this.params.editorType() === '%' ? 0 : this.startingValue);
    }

    clearFilter(): void {
        this.params.clearFilterCallback(this.params);
        this.params.api.stopEditing(true);
    }

    revertCellValue(): void {
        this.params.revertValueCallback(this.params);
        this.params.api.stopEditing(true);
    }

    getValue(): number {
        const returnVal = parseFloat(removeCommasFromValue(this.filterRef.nativeElement.value));
        return isNaN(returnVal)
            ? this.startingValue
            : this.params.editorType() === '%'
                ? this.startingValue + this.startingValue * returnVal / 100
                : returnVal;
    }

    saveFilterRef(event: ElementRef): void {
        this.filterRef = event;
    }

    ngAfterViewChecked(): void {
        if (!this.editorOpened) {
            const popup = document.getElementsByClassName('ag-popup-editor').item(0);
            if (popup) {
                (popup as HTMLElement).focus();
                this.filterRef.nativeElement.focus();
                if (this.params.eventKey?.length !== 1) {
                    this.filterRef.nativeElement.select();
                }
                this.editorOpened = true;
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    isPopup(): boolean {
        return true;
    }

}

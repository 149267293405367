import { Component, Inject } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgGridApiDirective } from '@shared/helpers/ag-grid/ag-grid';
import { UserAdvertiserUploadInfo } from '@models/admin/admin';

export interface InfoGridPopupProps {
    rows: UserAdvertiserUploadInfo[];
    colDefs: ColDef[];
}

@Component({
    selector: 'app-info-grid-popup',
    templateUrl: './info-grid-popup.component.html',
    styleUrls: ['./info-grid-popup.component.scss'],
    standalone: false,
})
export class InfoGridPopupComponent extends AgGridApiDirective<unknown> {
    colDefs: ColDef[];
    rows: UserAdvertiserUploadInfo[];

    constructor(
        public dialogRef: MatDialogRef<InfoGridPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoGridPopupProps,
    ) {
        super();
        this.rows = data.rows;
        this.colDefs = data.colDefs;
    }

    closeDialog() {
        this.dialogRef.close();
    }

}

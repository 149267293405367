import { Pipe, PipeTransform } from '@angular/core';
import { DateFilter, Filter, FilterTypes } from '@models/filter-types';

@Pipe({
    name: 'isDateFilter',
    standalone: false,
})
export class IsDateFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is DateFilter {
        return value?.Type === FilterTypes.DateFilterType;
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {  SegmentPopupProps } from '@models/rate-card/rate-card';
@Component({
    selector: 'app-add-segment-popup',
    templateUrl: './add-segment-popup.component.html',
    styleUrls: ['./add-segment-popup.component.scss'],
    standalone: false,
})
export class AddSegmentPopupComponent implements OnInit {

    constructor(
      public dialogRef: MatDialogRef<AddSegmentPopupComponent>,
      protected authSvc: AuthService,
      protected lookupSvc: LookupV2Service,
      @Inject(MAT_DIALOG_DATA) public data: SegmentPopupProps,
    ) {
    }

    ngOnInit(): void {

    }

    close() {
        this.dialogRef.close();
    }

}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DateFilter } from '@models/filter-types';
import moment from 'moment';
import { DateSelectCellParams } from '@models/ag-grid';

const MIDNIGHT_TODAY = moment().startOf('day');

@Component({
    selector: 'app-date-select-cell',
    templateUrl: './date-select-cell.component.html',
    styleUrls: ['./date-select-cell.component.scss'],
    standalone: false,
})
export class DateSelectCellComponent implements ICellRendererAngularComp {

    public params: DateSelectCellParams;

    dateFilter: DateFilter;

    constructor() {
    }

    agInit(params: DateSelectCellParams): void {
        this.params = params;
        this.dateFilter = new DateFilter('Date')
            .Default(this.params.value)
            .DisplayKey(false)
            .Filter((date) => {
                if (date < MIDNIGHT_TODAY) {
                    return false;
                }
                if (this.params.filterOnStartDate) {
                    return date.day() === moment(this.params.value).day();
                }
                return true;
            });
    }

    public onChange(event) {
        if (
            this.params.data
            && this.params.data[this.params.colDef.field]
            && this.params.data[this.params.colDef.field].toString() !== event.toString()
        ) {
            this.params.data[this.params.colDef.field] = event;
            this.params.api.refreshCells({ force: true });
        }
    }

    refresh() {
        return true;
    }
}

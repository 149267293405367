import { Component, Input } from '@angular/core';
import { TimeFilter } from '@models/filter-types';
import { Moment } from 'moment/moment';

@Component({
    selector: 'app-time-select',
    templateUrl: './time-select.component.html',
    styleUrls: ['./time-select.component.scss'],
    standalone: false,
})
export class TimeSelectComponent{

    @Input() property: TimeFilter;
    @Input() icon: string;
    @Input() disabled = true;
    @Input() val: Moment;

    constructor() {

    }
}

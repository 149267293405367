<mat-toolbar class="topnav level" data-cy="toolbar">
    <div class="level-left is-flex">
        <div class="level-item">
            <span class="rate-optics-logo">
                <img src="assets/images/rate_optics_logo.svg"/>
            </span>
        </div>
        <div *ngIf="config && showNavLinks" class="level-item main-menu-group">
            <a class='button nav-button' data-cy="main-menu" [matMenuTriggerFor]="rateOpt">
                <span class="rate-optics-main-menu">Main Menu</span>
                <i class="fas fa-fw fa-caret-down" aria-hidden="true"></i>
            </a>
            <mat-menu #rateOpt class="nav-buttons-dropdown border-radius-0">
                <a *ngIf="!config.customerCapability.rate_optics.dashboard?.access
                || config.roleCapability.rate_optics.dashboard?.access"
                   data-cy="main-menu-dashboard"
                   [attr.disabled]="config.customerCapability.rate_optics.dashboard?.access ? null : true"
                   title="{{config.customerCapability.rate_optics.dashboard?.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="dashboardRoute">
                    <i class="fas fa-fw {{icons.dashboard.iconClass}} is-size-5"></i>
                    <span>{{icons.dashboard.label}}</span>
                </a>

                <a *ngIf="(!config.customerCapability.rate_optics.rate_card.access
                || config.roleCapability.rate_optics.rate_card.access)"
                   data-cy="main-menu-rate-card"
                   [attr.disabled]="config.customerCapability.rate_optics.rate_card.access ? null : true"
                   title="{{config.customerCapability.rate_optics.rate_card.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="pricingHubRoute">
                    <i class="fas fa-fw {{icons.pricingHub.iconClass}} is-size-5"></i>
                    <span>{{icons.pricingHub.label}}</span>
                </a>

                <a *ngIf="!config.customerCapability.rate_optics.bulk_rates?.access
                || config.roleCapability.rate_optics.bulk_rates?.access"
                   data-cy="main-menu-bulk-rates"
                   [attr.disabled]="config.customerCapability.rate_optics.bulk_rates?.access ? null : true"
                   title="{{config.customerCapability.rate_optics.bulk_rates?.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="bulkRatesRoute" >
                    <i class="fas fa-fw {{icons.bulkRates.iconClass}} is-size-5"></i>
                    <span>{{icons.bulkRates.label}}</span>
                </a>
                <a *ngIf="!config.customerCapability.rate_optics.rate_generator.access
                || config.roleCapability.rate_optics.rate_generator.access"
                   data-cy="main-menu-rate-generator"
                   [attr.disabled]="config.customerCapability.rate_optics.rate_generator.access ? null : true"
                   title="{{config.customerCapability.rate_optics.rate_generator.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="rateGeneratorRoute">
                    <i class="fas fa-fw {{icons.rateGenerator.iconClass}} is-size-5"></i>
                    <span>{{icons.rateGenerator.label}}</span>
                </a>
                <a *ngIf="!config.customerCapability.rate_optics.advertiser_insights?.access
                || config.roleCapability.rate_optics.advertiser_insights?.access"
                   data-cy="main-menu-advertiser-insights"
                   [attr.disabled]="config.customerCapability.rate_optics.advertiser_insights?.access ? null : true"
                   title="{{config.customerCapability.rate_optics.advertiser_insights?.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="advertiserInsightsRoute">
                    <i class="fas fa-fw {{icons.advertiserInsights.iconClass}} is-size-5"></i>
                    <span>{{icons.advertiserInsights.label}}</span>
                </a>
                <a *ngIf="!config.customerCapability.rate_optics.inventory_insights?.access
                || config.roleCapability.rate_optics.inventory_insights?.access"
                   data-cy="main-menu-inventory-insights"
                   [attr.disabled]="config.customerCapability.rate_optics.inventory_insights?.access ? null : true"
                   title="{{config.customerCapability.rate_optics.inventory_insights?.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="inventoryInsightsRoute">
                    <i class="fas fa-fw {{icons.inventoryInsights.iconClass}} is-size-5"></i>
                    <span>{{icons.inventoryInsights.label}}</span>
                </a>
                <a *ngIf="config.roleCapability.rate_optics?.aida?.access"
                   data-cy="main-menu-rate-card"
                   [attr.disabled]="config.customerCapability.rate_optics?.aida?.access ? null : true"
                   title="{{config.customerCapability.rate_optics?.aida?.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="aidaRoute">
                    <i class="fas fa-fw {{icons.aida.iconClass}} is-size-5"></i>
                    <span>{{icons.aida.label}}</span>
                </a>
                <a *ngIf="!config.customerCapability.rate_optics.political_lur.access
                || config.roleCapability.rate_optics.political_lur.access"
                   data-cy="main-menu-political-lur"
                   [attr.disabled]="config.customerCapability.rate_optics.political_lur.access ? null : true"
                   title="{{config.customerCapability.rate_optics.political_lur.access ? null : featureNotPurchased}}"
                   class="button nav-dropdown-button" [routerLink]="politicalLurRoute">
                    <i class="fas fa-fw {{icons.politicalLUR.iconClass}} is-size-5"></i>
                    <span>{{icons.politicalLUR.label}}</span>
                </a>
                <a *ngIf="!config.customerCapability.rate_optics.rate_performance?.access
                || config.roleCapability.rate_optics.rate_performance?.access"
                   data-cy="main-menu-rate-performance"
                   class="button nav-dropdown-button"
                   [attr.disabled]="config.customerCapability.rate_optics?.rate_performance?.access ? null : true"
                   title="{{config.customerCapability.rate_optics.rate_performance?.access ? null : featureNotPurchased}}"
                   [routerLink]="ratePerformanceRoute">
                    <i class="fas fa-fw {{icons.ratePerformance.iconClass}} is-size-5"></i>
                    <span>{{icons.ratePerformance.label}}</span>
                </a>
            </mat-menu>
        </div>
        <div *ngIf="config && showNavLinks" class="level-item main-menu-group">
            <i class="fas fa-fw {{icons.mainMenu.iconClass}} is-size-5"></i>
            <span class="rate-optics-main-menu">{{icons.mainMenu.label}}</span>
        </div>
        <div *ngIf="config && showNavLinks && showInternal" class="level-item main-menu-group">
            <a class='button nav-button' data-cy="internal-menu" [matMenuTriggerFor]="raInternal">
                <span class="rate-optics-main-menu">RA Internal</span>
                <i class="fas fa-fw fa-caret-down" aria-hidden="true"></i>
            </a>
            <mat-menu #raInternal class="nav-buttons-dropdown border-radius-0">
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-update-status"
                  [routerLink]="updateStatusRoute">
                    <span>Channel Update Status</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-remove-channels"
                  [routerLink]="removeChannelsRoute">
                    <span>Remove Channels</span>
                </a>
                <a class="button nav-dropdown-button"
                  [routerLink]="roleConfigRoute">
                    <span>Role Config</span>
                </a>
                <a class="button nav-dropdown-button"
                  [routerLink]="roleConfigRouteV2">
                    <span>Role Config V2</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-role-config"
                  [routerLink]="configRoute">
                    <span>Config</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-edit-whitelist"
                  [routerLink]="editWhiteListRoute">
                    <span>Edit Whitelist</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-emailed-reports"
                  [routerLink]="emailedReportRoute">
                    <span>Emailed Reports</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-political-dayparts"
                  [routerLink]="politicalDaypartsRoute">
                  <span>Political Dayparts</span>
                </a>
                <a class="button nav-dropdown-button"
                  data-cy="internal-menu-aida"
                  [routerLink]="aidaAdminRoute">
                  <span>Create Mock Proposal</span>
                </a>
            </mat-menu>
        </div>
    </div>
    <div class="level-right is-flex right-side-nav">
        <ng-content></ng-content>
        <div class="level-item">
            <a class="button nav-button" data-cy="user-menu" [matMenuTriggerFor]="userMenu">
                <i class="fas fa-fw fa-user-circle is-size-6"></i>
                <strong>{{userName + (this.customer ? " (" + this.customer + ") " : "")}} <i class="fas fa-fw fa-caret-down" aria-hidden="true"></i> </strong>
            </a>
            <mat-menu #userMenu class="nav-buttons-dropdown border-radius-0">
                <a *ngIf="config?.roleCapability.rate_optics.admin.access"
                   [routerLink]="configurationSettings"
                   data-cy="user-menu-configuration-settings"
                   class="button nav-dropdown-button">
                    <i class="fas fa-fw fa-user-lock is-size-6" aria-hidden="true"></i>
                    <span>Configuration Settings</span>
                </a>
                <!-- TODO: fix user settings -->
                <a *ngIf="false && config?.roleCapability.rate_optics.rate_card.access && config?.roleCapability.rate_optics.rate_card.edit"
                   [routerLink]="userSettingsRoute"
                   data-cy="user-menu-user-settings"
                   class="button nav-dropdown-button">
                    <i class="fas fa-fw fa-cog is-size-6" aria-hidden="true"></i>
                    <span>User Settings</span>
                </a>
                <a *ngIf="config?.roleCapability?.rate_optics?.user_management?.access || isRaUser"
                   [routerLink]="userManagementRoute"
                   data-cy="user-menu-permissions"
                   class="button nav-dropdown-button">
                    <i class="fas fa-fw fa-users-cog is-size-6" aria-hidden="true"></i>
                    <span>Permissions</span>
                </a>
                <a *ngIf="config?.allowedIds.split(',').length > 1" [routerLink]="config?.allowedIds.split(',').length > 1 ? '/' + urlStore.ui.switchCustomer : null"
                   data-cy="user-menu-switch-customer"
                   class="button nav-dropdown-button">
                    <i class="fas fa-fw fa-sync-alt is-size-6" aria-hidden="true"></i>
                    <span>Switch Organization</span>
                </a>
                <a *ngIf="config?.roleCapability.rate_optics.user_activity_report?.access"
                   data-cy="main-menu-user-activity-report"
                   class="button nav-dropdown-button"
                   [routerLink]="userActivityReportRoute">
                    <i class="fas fa-fw fa-person-arrow-up-from-line is-size-5"></i>
                    <span>User Activity Report</span>
                </a>
                <a
                   data-cy="main-menu-about"
                   class="button nav-dropdown-button"
                   [routerLink]="'/' + urlStore.ui.admin.about">
                    <i class="fas fa-fw fa-question is-size-5"></i>
                    <span>About</span>
                </a>
                <a (click)="authSvc.logout()" class="button nav-dropdown-button"
                   data-cy="user-menu-logout">
                    <i class="fas fa-fw fa-sign-out-alt is-size-6" aria-hidden="true"></i>
                    <span>Logout</span>
                </a>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>

<a class="close-button" (click)="closeForm()"><i class="fas fa-times"></i></a>
<div class="woms-export-header width-100-pct has-text-centered">
    {{title}}
</div>
<div class="wizard-instructions">
    {{instructions}}
</div>
<div class="step align-center justify-center step-count-banner">
    <div class="step-count">
        Step {{stepCount + 1}} of {{steps.length}}{{showStepName ? ':' : ''}}
    </div>
    <div class="step-name" *ngIf="showStepName">
        {{steps[stepCount]}}
    </div>
</div>
<div class="wizard-content">
<ng-content></ng-content>
</div>
<div class="level is-full-width button-row">
    <div class="level-left" *ngIf="backwardText[stepCount] !== ''">
        <a class="button level-item is-primary" (click)="goBack()">
            <i *ngIf="stepCount === 0" class="fas fa-fw fa-times"></i>
            <i *ngIf="stepCount !== 0" class="fa-fw far fa-arrow-alt-circle-left"></i>
            {{stepCount === 0 ? 'Cancel' : 'Go Back'}}
        </a>
    </div>
    <div class="level-right" *ngIf="forwardText[stepCount] !== ''">
        <a class="button level-item is-primary" (click)="goForward()">
            <i *ngIf="stepCount === steps.length - 1" class="fa-fw fas fa-file-download"></i>
            {{forwardText[stepCount]}}
            <i *ngIf="stepCount !== steps.length - 1" class="fa-fw far fa-arrow-alt-circle-right"></i>
        </a>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { DaypartTierRow, RateCardTier } from '@models/rate-card/rate-card';
import { AgGridApiDirective } from '@shared/helpers/ag-grid/ag-grid';
import { ColDef } from 'ag-grid-community';
import { getColumnDefs } from '@shared/helpers/ag-grid/ag-grid-builder';
import { SelectFilter } from '@models/filter-types';
import { GridColumn } from '@models/lookup';

@Component({
    selector: 'app-tier-multiplier-grid',
    templateUrl: './tier-multiplier-grid.component.html',
    styleUrls: ['./tier-multiplier-grid.component.scss'],
    standalone: false,
})
export class TierMultiplierGridComponent extends AgGridApiDirective<DaypartTierRow> implements OnInit {


    @Input() daypartTierRows: DaypartTierRow[];
    @Input() primaryTierFilter: SelectFilter<RateCardTier>;
    @Input() tierUsed: SelectFilter<RateCardTier>;
    colDefs: ColDef[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        const columns: GridColumn[] = [
            {
                name: 'Daypart Name',
                field: 'daypartName',
                format: { type: 'text' },
            },
        ];
        this.primaryTierFilter.options.forEach(option => {
            if (option.id !== this.primaryTierFilter.Value.id) {
                columns.push({
                    name: option.name,
                    field: option.name,
                    format: { type: 'number', format: '1.0-2'  },
                    isEditable: true,
                });
            }
        });
        this.colDefs = getColumnDefs(columns);
        this.colDefs.forEach(colDef =>{
            colDef.cellClass = ['justify-center'];
        });
    }

    multiplierCascade(event) {
        const daypartName = event?.data?.daypartName;
        const tierName = event.column.colId;
        const multiplier = event.data?.[tierName];
        if (daypartName === 'All Dayparts' && multiplier) {
            this.daypartTierRows.forEach(daypartRow => {
                daypartRow[tierName] = multiplier;
            });
        }
        this.gridApi.setGridOption('rowData', this.daypartTierRows);
        this.gridApi.refreshCells();
    }

}

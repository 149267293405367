import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AfterViewInit, Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Moment } from 'moment';
import { DateFilter } from '@models/filter-types';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { segmentTrack } from '@shared/helpers/functions/helpers';
import { Router } from '@angular/router';

export const DATE_FORMAT = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const DATE_CONSTANTS = {
    monday: 1,
    december: 11,
    january: 0,
    firstDayOfMonth: 1,
};

export const US_LOCALE ='en-US';

export class MyDateAdapter extends MomentDateAdapter {
    getFirstDayOfWeek(): number {
        return DATE_CONSTANTS.monday;
    }
}

@Component({
    selector: 'app-date-select',
    templateUrl: './date-select.component.html',
    styleUrls: ['./date-select.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: US_LOCALE },
        { provide: DateAdapter, useClass: MyDateAdapter, deps: [MAT_DATE_LOCALE] },
    ],
    standalone: false,
})
export class DateSelectComponent implements AfterViewInit{
    @Output() valueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

    @Input() property: DateFilter;

    @Input() isEndDate = false;
    @Input() icon: string;
    @Input() disabled = true;

    @ViewChild('datePicker') dateFilterHTML: MatDatepicker<Moment>;

    startView = 'month';
    private dateViewValue: string;

    constructor(
        private router: Router,
    ) {
    }

    @Input()
    get dateView() {
        return this.dateViewValue;
    }

    set dateView(v: string) {
        this.dateViewValue = v;
        if (this.property.Value) {
            this.property.Value = this.property.resetDate(this.property.Value.clone());
        }
        this.updateStartView();
    }

    ngAfterViewInit(): void {
        if (this.dateFilterHTML) {
            this.property.filterRef = this.dateFilterHTML;
            this.dateFilterHTML.stateChanges.subscribe(state => {
                this.valueChanged.emit();
            });
        }
    }


    dateChanged(event: MatDatepickerInputEvent<Moment>): void {
        this.property.Value = event.value;

    }

    chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>): void {
        if (this.startView === 'multi-year') {
            const ctrlValue = this.property.Value.clone();
            ctrlValue.year(normalizedYear.year());
            ctrlValue.month(this.isEndDate ? DATE_CONSTANTS.december : DATE_CONSTANTS.january);
            ctrlValue.date(this.isEndDate ? ctrlValue.daysInMonth() : DATE_CONSTANTS.firstDayOfMonth);

            this.property.Value = ctrlValue;
            this.closeDatePicker(datepicker);
        }
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
        if (this.startView === 'year') {
            const ctrlValue = this.property.Value.clone();
            ctrlValue.year(normalizedMonth.year());
            ctrlValue.month(normalizedMonth.month());
            ctrlValue.date(this.isEndDate ? ctrlValue.daysInMonth() : DATE_CONSTANTS.firstDayOfMonth);

            this.property.Value = ctrlValue;
            this.closeDatePicker(datepicker);
        }
    }

    updateStartView(): void {
        switch (this.dateView) {
        case ('Week'): {
            this.startView = 'month';
            break;
        }
        case ('Date'): {
            this.startView = 'month';
            break;
        }
        case ('Year'): {
            this.startView = 'multi-year';
            break;
        }
        default: {
            this.startView = 'year';
        }
        }
    }

    closeDatePicker(datepicker: MatDatepicker<Moment>): void {
        datepicker.close();
    }

    closed(): void {
        segmentTrack(
            'User Selected From Dropdown',
            {
                dropdown: this.property.Key,
                selection: this.property.transform(this.property.Value),
                screen: this.router.url,
            },
        );
    }
}

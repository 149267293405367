import {
    Component,
    AfterViewInit,
    ChangeDetectorRef, Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Filter, GetTemplateValue } from '@models/filter-types';
import { validateFilters } from '@shared/helpers/functions/filter-helpers';
import { Observable } from 'rxjs';
import { NotificationService } from '@services/notification/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { handleCustomErrorCode } from '@shared/helpers/functions/helpers';


@Component({
    selector: 'app-modal-edit-popup',
    templateUrl: './modal-edit-popup.component.html',
    styleUrls: ['./modal-edit-popup.component.scss'],
    standalone: false,
})
export class ModalEditPopupComponent implements AfterViewInit {

    title: string;
    filters: Filter<unknown>[];
    saveLbl: string;

    filterInit = false;
    isLoading = false;

    constructor(
        public dialogRef: MatDialogRef<ModalEditPopupComponent>,
        public changeDetectorRef: ChangeDetectorRef,
        private snackBarSvc: NotificationService,
        @Inject(MAT_DIALOG_DATA) private data: {
            title: string;
            filters: Filter<unknown>[];
            saveLbl: string;
            closeFunc: (...filters) => Observable<unknown>;
        },
    ) {
        this.title = data.title;
        this.filters = data.filters;
        this.filterInit = true;
        this.saveLbl = data.saveLbl ? data.saveLbl : 'Save';
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    closeDialog() {
        if (!this.isLoading) {
            this.dialogRef.close();
        }
    }

    validateFilters() {
        return validateFilters([this.filters]);
    }

    validateAndClose() {
        if (this.validateFilters().isValid) {
            if (this.data.closeFunc && !this.isLoading) {
                this.isLoading = true;
                this.data.closeFunc(GetTemplateValue(this.filters))
                    .subscribe(
                        data => {
                            this.dialogRef.close(data);
                            this.isLoading = false;
                        },
                        (error: HttpErrorResponse) => {
                            this.isLoading = false;
                            handleCustomErrorCode(
                                (message, errorType) => this.snackBarSvc.openSnackBar(message, errorType),
                                error,
                                `Failed to ${this.saveLbl}`,
                            );
                        },
                    );
            } else {
                this.dialogRef.close(this.filters);
            }
        }
    }
}

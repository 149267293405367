import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    standalone: false,
})
export class ExpansionPanelComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() loading = true;
    @Input() error = false;
    @Input() hoverText = '';
    @Input() panelClasses = 'ra-primary-blue';
    @Input() panelFrameClasses = '';

}

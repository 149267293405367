import { Component, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

export interface ICheckboxHeaderParams extends IHeaderParams {
    checked?: boolean;
    indeterminate?: boolean;
    onChecked?: (isChecked: boolean) => void;
    name?: string;
}

@Component({
    selector: 'app-ag-grid-checkbox-header',
    templateUrl: './ag-grid-checkbox-header.component.html',
    styleUrls: ['./ag-grid-checkbox-header.component.scss'],
    standalone: false,
})
export class AgGridCheckboxHeaderComponent implements IHeaderAngularComp {
    @ViewChild('matCheckbox', { static: true }) matCheckboxRef!: MatCheckbox;
    params: ICheckboxHeaderParams;

    checked: boolean;
    indeterminate: boolean;
    disabled = false;

    constructor() {
    }

    agInit(params: ICheckboxHeaderParams) {
        this.updateParams(params);
        if(this.params.eGridHeader && this.params.eGridHeader instanceof HTMLElement ){
            this.params.eGridHeader.addEventListener('keydown', this.onHeaderKeyDown.bind(this));
        }
    }

    onHeaderKeyDown(keyboardEvent: KeyboardEvent){
        if(keyboardEvent.key === ' ' || keyboardEvent.key === 'Enter'){
            this.matCheckboxRef.toggle();
            this.change({ checked: this.matCheckboxRef.checked } as MatCheckboxChange);
            keyboardEvent.preventDefault();
        }
    }

    refresh(params: ICheckboxHeaderParams) {
        this.updateParams(params);
        return true;
    }

    change(event: MatCheckboxChange) {
        this.params.onChecked(event.checked);
    }

    private updateParams(params: ICheckboxHeaderParams) {
        this.params = params;
        this.checked = params.checked ? params.checked : false;
        this.indeterminate = params.indeterminate ? params.indeterminate : false;
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
} from '@angular/material/dialog';
import { EditSegmentNameParams, SystemSegment, SystemSegmentPopupProps, HiatusWeekSelection, RateCardMetricRowV2 } from '@models/rate-card/rate-card';
import { Segmentation, UiConfig } from '@models/config';
import { AuthService } from '@services/auth/auth.service';
import { forkJoin, Observable } from 'rxjs';
import { AutocompleteFilter, StringFilter, MultiSelectFilter, TimeFilter } from '@models/filter-types';
import { NotificationService } from '@services/notification/notification.service';
import { MessageDialogComponent } from '@shared/components/message-dialog/message-dialog.component';
import { LookupItem, StringLookupItem } from '@models/lookup';
import { dateFormatter } from '@shared/helpers/functions/helpers';
import moment from 'moment';


@Component({
    selector: 'app-edit-system-segment-popup',
    templateUrl: './edit-system-segment-popup.component.html',
    styleUrls: ['./edit-system-segment-popup.component.scss'],
    standalone: false,
})
export class EditSystemSegmentPopupComponent implements OnInit {
    config: UiConfig;
    segmentTimeFormat: string;
    trimSegmentTimeMeridiem = false;
    sortedFilteredSegments: Segmentation[];
    sortedStringSegments: Segmentation[];
    segments: {
        segment: SystemSegment;
        newSegmentFilters: AutocompleteFilter[] | TimeFilter[];
        originalSegmentFilter: StringFilter;
        segmentHeader: string;
    }[] = [];
    weeks: string[];
    airedWeeks: string[];
    inherentHiatusWeeks: string[];
    hiatusWeeks: string[];
    hiatusWeeksFilter: MultiSelectFilter<string>;


    constructor(
        private lookupSvc: LookupV2Service,
        private authSvc: AuthService,
        private snackBarSvc: NotificationService,
        public dialogRef: MatDialogRef<EditSystemSegmentPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SystemSegmentPopupProps,
    ) {
        this.config = this.authSvc.getConfig();
        this.segmentTimeFormat = this.config.customerConfig.segment_time_format ? this.config.customerConfig.segment_time_format : 'hh:mm A';
        if (this.segmentTimeFormat.includes('-1')) {
            this.segmentTimeFormat = this.segmentTimeFormat.replace('-1','');
            this.trimSegmentTimeMeridiem = true;
        }
        this.sortedFilteredSegments = this.config.channelSegmentation[this.data.channelId].rate_card
            .sort((first, second) => first.segment_order - second.segment_order)
            .filter(segment => !segment.is_dow);
        this.sortedStringSegments = this.config.channelSegmentation[this.data.channelId].rate_card
            .sort((first, second) => first.segment_order - second.segment_order)
            .filter(segment => !segment.is_dow && !segment.is_start_time && !segment.is_end_time);
    }

    ngOnInit() {
        const subs: Observable<HiatusWeekSelection[] | SystemSegment>[] = [];

        this.sortedFilteredSegments.forEach(segment => {
            subs.push(
                this.lookupSvc.getSystemSegments(
                    this.data.channelId,
                    this.data.segmentation[segment.segment_type],
                    segment.segment_type,
                    this.data.baseSegmentationId,
                ),
            );
        });
        subs.push(
            this.lookupSvc.getSystemSegmentHiatusWeeks(
                this.data.channelId,
                this.data.baseSegmentationId,
                this.data.segmentation,
                this.data.daypartId,
                this.data.spotTypeId,
            ),
        );
        forkJoin(subs).subscribe((data) => {
            data.slice(0, -1).forEach((segment: SystemSegment) => {
                const fullSegment = this.sortedFilteredSegments.find(seg=>seg.segment_type === segment.segmentType);
                const segmentBuilt = {
                    segment,
                    segmentHeader: this.sortedFilteredSegments
                        .find(segmentation => segmentation.segment_type === segment.segmentType).segment_col_header,
                    originalSegmentFilter: new StringFilter('Original').Default(segment.originalName).Readonly(true).SkipValidate(true),
                    newSegmentFilters: this.createSegmentFilter(segment, fullSegment),
                };
                this.segments.push(segmentBuilt);
            });
            this.segments.forEach((segment)=> {
                segment.newSegmentFilters.forEach((segmentFilter)=> {
                    if ((segmentFilter instanceof AutocompleteFilter)) {
                        segmentFilter.Load()
                            .subscribe((options)=>{
                                if ((segmentFilter instanceof AutocompleteFilter)) {
                                    segmentFilter.finishLoad(options);
                                }
                            });
                    }
                });
            });
            const hiatusReturn = data.slice(-1)[0] as HiatusWeekSelection[];
            this.weeks = hiatusReturn.map(x => x.week);
            this.airedWeeks = hiatusReturn.map(x => x.airedWeek);
            this.inherentHiatusWeeks = this.weeks.filter(week => !this.airedWeeks.includes(week));
            this.hiatusWeeks = hiatusReturn.map(x => x.hiatusWeek).filter(x => x);
            const selectedHiatusWeeks = [... this.inherentHiatusWeeks, ...this.hiatusWeeks];
            this.hiatusWeeksFilter = new MultiSelectFilter<string>('Hiatus Weeks (OPTIONAL)')
                .Options(this.weeks)
                .Default(selectedHiatusWeeks || [])
                .Clear(() => this.hiatusWeeksFilter.Value = [])
                .TriggerText((o, s) => o.length === s.length ? 'All ' + 'Hiatus Weeks' : `${s.length} ` + 'Hiatus Weeks' + ' Selected')
                .Transform((v: string[]) => v.map(week => dateFormatter(week, 'yyyy/MM/dd')))
                .Validator((selected: string[]) => this.hiatusWeeksFilter.options.length > 0 ? selected.length < this.hiatusWeeksFilter.options.length : true, () => 'Must have at least one non-hiatus week');
        });
    }

    save(): void {
        const subs: Observable< RateCardMetricRowV2[] | EditSegmentNameParams[]>[] = [];

        subs.push(this.lookupSvc.saveSystemSegmentHiatusWeeks(
            this.data.channelId,
            this.data.baseSegmentationId,
            this.data.segmentation,
            this.data.daypartId,
            this.data.spotTypeId,
            this.hiatusWeeksFilter?.Value.filter(hiatusWeek => (!this.inherentHiatusWeeks.includes(hiatusWeek))),
            this.hiatusWeeks.filter(hiatusWeek => (!this.hiatusWeeksFilter.Value.includes(hiatusWeek))),
            this.data.reloadParams,
            this.data.rateCardTab,
        ));

        const saveSegments = this.segments.map(segment => (
            {
                channelId: this.data.channelId,
                baseSegmentationId: this.data.baseSegmentationId,
                daypartId: this.data.daypartId,
                spotTypeId: this.data.spotTypeId,
                dynamicSegmentation: this.data.segmentation,
                segmentId: segment.segment.segmentId,
                segmentType: segment.segment.segmentType,
                segmentName: this.getFinalSegmentName(
                    segment.originalSegmentFilter,
                    segment.newSegmentFilters,
                    segment.segment,
                ),
            }
        ));
        const textDialogRef = this.data.matDialog.open(MessageDialogComponent, {
            disableClose: true,
            data: {
                message: 'Please wait while this change is saved',
            },
        });
        subs.push(this.lookupSvc.saveSystemSegmentEdits(saveSegments));

        forkJoin(subs).subscribe((data) => {
            const savedSegments = data[1] as EditSegmentNameParams[];
            const editedHiatusRow = data[0] as RateCardMetricRowV2[];
            this.data.getRateCardSegments();
            this.data.afterSave(savedSegments, editedHiatusRow);
            textDialogRef.close();
            this.dialogRef.close();
        }, failure => {
            this.snackBarSvc.openSnackBar(`Failed to save program name and hiatus weeks`, 'error');
            textDialogRef.close();
        });
    }

    createSegmentFilter(segment: SystemSegment, fullSegment: Segmentation):  AutocompleteFilter[] | TimeFilter[] {
        if ((fullSegment.is_start_time && !fullSegment.is_end_time) || (!fullSegment.is_start_time && fullSegment.is_end_time) || (fullSegment.is_start_time && fullSegment.is_end_time && !segment.originalName.includes('-'))) {
            return [new TimeFilter('Time')
                .Default(moment(segment.currentName ? segment.currentName : segment.originalName, this.segmentTimeFormat))];
        }
        if (fullSegment.is_start_time && fullSegment.is_end_time && segment.originalName.includes('-')) {
            const segmentToParse = segment.currentName ? segment.currentName : segment.originalName;
            const startTimeToParse = segmentToParse.split(' - ')[0];
            const endTimeToParse = segmentToParse.split(' - ')[1];
            const startTiemFilter = new TimeFilter('Start Time')
                .Default(moment(startTimeToParse, this.segmentTimeFormat));
            const endTimeFilter = new TimeFilter('End Time')
                .Default(moment(endTimeToParse, this.segmentTimeFormat));
            return [startTiemFilter, endTimeFilter];
        } else {
            return [new AutocompleteFilter<StringLookupItem | LookupItem>('New')
                .Defer(() =>
                    this.lookupSvc.getRateCardSegments(
                        [this.data.channelId],
                        [this.data.daypartId],
                        this.sortedFilteredSegments.find(seg=>seg.segment_type === segment.segmentType),
                    ))
                .Transform((item) => (item as (StringLookupItem | LookupItem) ).name)
                .Default(segment.currentName)];
        }
    }

    getFinalSegmentName(
        originalSegmentFilter: StringFilter,
        newSegmentFilters: AutocompleteFilter[] | TimeFilter[],
        segment: SystemSegment,
    ): string {
        if (!newSegmentFilters[0].Value) {
            return originalSegmentFilter.Value;
        } else if (
            newSegmentFilters.length > 1
            && newSegmentFilters[0] instanceof TimeFilter
            && newSegmentFilters[1] instanceof TimeFilter
        ) {
            if (
                segment.currentName == null
                && moment(originalSegmentFilter.Value.split(' - ')[0], this.segmentTimeFormat).isSame(moment(newSegmentFilters[0].Value))
                && moment(originalSegmentFilter.Value.split(' - ')[1], this.segmentTimeFormat).isSame(moment(newSegmentFilters[1].Value))
            ) {
                return originalSegmentFilter.Value;
            }
            let startSegmentTimeName = newSegmentFilters[0].Value.format(this.segmentTimeFormat);
            let endSegmentTimeName = newSegmentFilters[1].Value.format(this.segmentTimeFormat);
            if (this.trimSegmentTimeMeridiem) {
                startSegmentTimeName = startSegmentTimeName.slice(0, -1);
                endSegmentTimeName = endSegmentTimeName.slice(0, -1);
            }
            return (startSegmentTimeName + ' - ' + endSegmentTimeName);
        } else if (
            segment.currentName == null
            && newSegmentFilters[0] instanceof TimeFilter
            && moment(newSegmentFilters[0].Value).isSame(moment(originalSegmentFilter.Value, this.segmentTimeFormat))
        ) {
            return originalSegmentFilter.Value;
        } else if (newSegmentFilters[0] instanceof TimeFilter) {
            const segmentTimeName = newSegmentFilters[0].Value.format(this.segmentTimeFormat);
            if (this.trimSegmentTimeMeridiem) {
                return segmentTimeName.slice(0, -1);
            }
            return segmentTimeName;
        } else if (newSegmentFilters[0] instanceof AutocompleteFilter) {
            return (typeof newSegmentFilters[0].Value === 'string')? newSegmentFilters[0].Value : newSegmentFilters[0].Value.name;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

}

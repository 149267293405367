import { Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { segmentButtonTrack } from '@shared/helpers/functions/helpers';


@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    standalone: false,
})
export class ToggleButtonComponent {
    @Input() label1: string;
    @Input() icon1: string;
    @Input() label2: string;
    @Input() icon2: string;
    @Input() toggleFlag = true;
    @Input() disabled = false;
    @Input() selectedTabLabel: string;

    @Output() action1 = new EventEmitter();
    @Output() action2 = new EventEmitter();

    @HostBinding('class.button') button = true;
    @HostBinding('class.is-link') small = true;

    toggle() {
        if (!this.disabled) {
            const trackingParams = this.selectedTabLabel ? { tab: this.selectedTabLabel } : {};
            segmentButtonTrack(this.toggleFlag ? this.label1 : this.label2, trackingParams);
            this.toggleFlag = !this.toggleFlag;
            if (this.toggleFlag) {
                this.action1.emit();
            } else {
                this.action2.emit();
            }
        }

    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UiConfig } from '@models/config';
import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { LAST_DATA_UPDATE } from '@shared/helpers/functions/helpers';
import { StationExceptionsPopupComponent } from 'src/app/rate-optics/shared/station-exceptions-popup/station-exceptions-popup.component';

@Component({
    selector: 'app-nav-last-data-update',
    templateUrl: './nav-last-data-update.component.html',
    styleUrls: ['./nav-last-data-update.component.scss'],
    standalone: false,
})
export class NavLastDataUpdateComponent implements OnInit {
    lastDataUpdate: string;
    stationExceptions = false;
    lastDataUpdateDescription: string;
    config: UiConfig;

    constructor(
    private lookupSvc: LookupV2Service,
    private matDialog: MatDialog,
    protected authSvc: AuthService,
    ) { }

    ngOnInit(): void {
        this.config = this.authSvc.getConfig();
        this.lookupSvc.getAsOfDates().subscribe(
            (dates) => {
                this.lastDataUpdate = dates.lastDataUpdate;
                this.lastDataUpdateDescription = LAST_DATA_UPDATE;
                this.stationExceptions = Boolean(dates.stationExceptions);
            },
        );
    }

    showStationExceptions(): void{
        this.matDialog.open(StationExceptionsPopupComponent, {
            width: '43em',
            height: '42em',
            data: {},
        });
    }

}

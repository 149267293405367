import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DownloadCellRendererParams } from '@models/ag-grid';


@Component({
    selector: 'app-button-cell',
    templateUrl: './download-cell.component.html',
    styleUrls: ['./download-cell.component.scss'],
    standalone: false,
})
export class DownloadCellComponent implements ICellRendererAngularComp {
    params: DownloadCellRendererParams;
    fileExists = false;
    fileName: string;

    constructor() { }

    agInit(params: DownloadCellRendererParams): void {
        this.params = params;
        this.fileExists = params.data?.supportingData !== null;
        if (this.fileExists) {
            this.fileName = params.data.supportingData;
        }
    }

    refresh() {
        return true;
    }

    onClick(): void {
        if (this.params && typeof this.params.callBack === 'function') {
            this.params.callBack(this.params.data);
        }
    }
}

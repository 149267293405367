import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
    // eslint-disable-next-line max-len
    'Using_this_{AG_Grid}_Enterprise_key_{AG-073619}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Revenue_Analytics,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_January_2026}____[v3]_[01]_MTc2ODE3NjAwMDAwMA==5802ede2b6bdc27fd56bc2325e6a1f2d'
);

declare global {
    interface Window {
        analytics: {
            track:  (name: string, args: {[key: string]: unknown}, id: {groupId: string}) => void;
            page: (id?: string) => void;
        };
    }
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

import { Pipe, PipeTransform } from '@angular/core';
import { removeCommasFromValue } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'noComma',
    standalone: false,
})
export class NoCommaPipe implements PipeTransform {

    transform(val: string): string {
        if (val !== undefined && val !== null) {
            return removeCommasFromValue(val);
        } else {
            return undefined;
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
    DynamicRateCardFilter,
    DigitalRateCardFilter,
    PoliticalRateCardFilter,
    SpecialEventFilter,
    SubscriptionDataType,
} from '@models/filter';
import { UrlStore } from '@shared/helpers/constants/url-store';
import {
    DateRange,
    LookupItem, MetricTypeItem,
    Grid,
    PricingTier, Quarter,
} from '@models/lookup';
import {
    WomsMapping,
    WomsMappings,
    WomsExport,
    RateCardMetricAlertCalculations,
    RateCardMetricOverrideV2,
    DynamicRateCardApiGrid,
    RateOpticsProgram,
    DynamicRateCardGrid,
    RateCardMetricAlerts,
    RateCardApiMetricAlerts,
    RateCardMetricRowV2,
    PoliticalRateCardGrid,
    RoundingRules,
    PoliticalRateCardApiGrid,
    RateCardMetricData,
    EnabledRatecard,
    AddSegmentPopupReturn,
    EditableSegment, GroupedMetricItem,
} from '@models/rate-card/rate-card';
import { PriorityCodeTierRow, RateOpticsPCodeTierSettings } from '@models/admin/admin';
import moment from 'moment';
import {
    appendSegmentationRequestParams,
    generateCachedGet,
    hasProperty,
} from '@shared/helpers/functions/helpers';
import {
    calculateRateCardAlerts2,
    dynamicRateCardCopyCell,
    politicalRateCardCopyCell,
    digitalRateCardCopyCell,
} from '@shared/helpers/functions/rate_card_helpers';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AuthService } from '@services/auth/auth.service';
import { RateOpticsDigitalTierSettings, RateOpticsTierSettings } from '@models/admin/admin';
import { PushNotificationService } from '@services/push-notification/push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class RateCardService {

    cacheData = {};
    private womsProgramCache: { [channel: number]: Observable<RateOpticsProgram[]> } = {};

    constructor(private http: HttpClient, private authSvc: AuthService, public pushNotificationSvc: PushNotificationService) {
    }

    getRateCardV2(
        filters: DynamicRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
    ): Observable<DynamicRateCardGrid<RateCardMetricRowV2>> {
        const body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            rateCardTab,
            metricTypes: metrics ? metrics : undefined,
            dayparts: filters.dayparts?.length ? filters.dayparts : undefined,
            spotTypes: filters.spotTypes?.length ? filters.spotTypes : undefined,
            dows: filters.dows?.length ? filters.dows : undefined,
            eventType: filters.eventType?.length ? filters.eventType : undefined,
        };

        // Removing undefined keys
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }

        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.rate_card);
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.rateCardV2,
            body,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    )),
            );
    }

    getRateCardPoliticalSummary(
        filters: DynamicRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
        politicalWindows: string[],
    ): Observable<DynamicRateCardGrid<RateCardMetricRowV2>> {
        const body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            rateCardTab,
            metricTypes: ['political_rate30'],
            dayparts: filters.dayparts?.length ? filters.dayparts : undefined,
            spotTypes: filters.spotTypes?.length ? filters.spotTypes : undefined,
            dows: filters.dows?.length ? filters.dows : undefined,
            eventType: filters.eventType?.length ? filters.eventType : undefined,
            politicalWindows,
            isPoliticalSummary: true,
        };

        // Removing undefined keys
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }

        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.political_lur);
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.politicalRateCardV2,
            body,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    )),
            );
    }

    getExcelExportRateCardV2(
        filters: DynamicRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
    ): Observable<DynamicRateCardGrid<RateCardMetricRowV2>> {
        let body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            dynamicPeriod: filters.dynamicRateGranularity,
            politicalPeriod: filters.politicalRateGranularity,
            dynamicMetricTypes: filters.dynamicMetricTypes,
            politicalMetricTypes: filters.politicalMetricTypes,
            period: filters.period,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            politicalWindows: filters.politicalWindows,
            rateCardTab,
            combinedExcelExport: true,
        };
        if (metrics) {
            body = { ...body, ...{ metricTypes: metrics }};
        }
        if (filters.dayparts?.length) {
            body = { ...body, ...{ dayparts: filters.dayparts }};
        }
        if (filters?.spotTypes?.length) {
            body = { ...body, ...{ spotTypes: filters.spotTypes }};
        }
        if (filters.dows?.length) {
            body = { ...body, ...{ dows: filters.dows }};
        }
        if (filters.eventType?.length) {
            body = { ...body, ...{ eventType: filters.eventType }};
        }
        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.rate_card);
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.excelExportData,
            body,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    )),
            );
    }

    asyncLoadRateCardV2(
        filters: DynamicRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
        subscriptionData: SubscriptionDataType,
    ): Observable<{ success: boolean }> {
        const body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            rateCardTab,
            metricTypes: metrics ? metrics : undefined,
            dayparts: filters.dayparts?.length ? filters.dayparts : undefined,
            spotTypes: filters.spotTypes?.length ? filters.spotTypes : undefined,
            dows: filters.dows?.length ? filters.dows : undefined,
            eventType: filters.eventType?.length ? filters.eventType : undefined,
            p256dh: subscriptionData?.p256dh,
            auth: subscriptionData?.auth,
            endpoint: subscriptionData?.endpoint,
        };
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }
        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.rate_card);
        return this.http.post<{ success: boolean }>(
            UrlStore.api.rateCard.asyncTriggerLoadRateCardV2,
            body,
        );
    }


    getDigitalRateCard(
        filters: DigitalRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
    ): Observable<DynamicRateCardGrid<RateCardMetricRowV2>> {
        let body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            products: filters.products,
            productTypes: filters.productTypes,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            rateCardTab,
        };
        if (metrics) {
            body = { ...body, ...{ metricTypes: metrics }};
        }
        // if (filters.dayparts?.length) {
        //     body = {...body, ...{dayparts: filters.dayparts}};
        // }
        // if (filters?.spotTypes?.length) {
        //     body = {...body, ...{spotTypes: filters.spotTypes}};
        // }
        // if (filters.dows?.length) {
        //     body = {...body, ...{dows: filters.dows}};
        // }
        // if (filters.eventType?.length) {
        //     body = {...body, ...{eventType: filters.eventType}};
        // }
        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.digital_rate_card);
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.digitalRateCard,
            body,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        undefined,
                        undefined,
                        response.tiers,
                        digitalRateCardCopyCell,
                    )),
            );
    }

    getPoliticalRateCard(
        filters: PoliticalRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
    ): Observable<PoliticalRateCardGrid<RateCardMetricRowV2>> {
        const body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            politicalWindows: filters.politicalWindows,
            rateCardTab,
            metricTypes: metrics ? metrics : undefined,
            dayparts: filters.dayparts?.length ? filters.dayparts : undefined,
            spotTypes: filters.spotTypes?.length ? filters.spotTypes : undefined,
            dows: filters.dows?.length ? filters.dows : undefined,
            eventType: filters.eventType?.length ? filters.eventType : undefined,
        };
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }
        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.rate_card);
        return this.http.post<PoliticalRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.politicalRateCardV2,
            body,
        ).pipe(switchMap((response: PoliticalRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
            calculateRateCardAlerts2(
                response.data,
                response.alerts,
                response.groupedColumns,
                undefined,
                undefined,
                response.tiers,
                politicalRateCardCopyCell,
            )));
    }

    asyncLoadPoliticalRateCard(
        filters: PoliticalRateCardFilter & { channel: number },
        metrics: string[],
        rateCardTab: number,
        subscriptionData: SubscriptionDataType,
    ): Observable<{ success: boolean }> {
        const body = {
            markets: filters.markets,
            stations: filters.stations,
            channels: filters.channels,
            channel: filters.channel,
            period: filters.period.id,
            startDate: filters.startDate,
            endDate: filters.endDate,
            channelGroup: filters.channelGroup,
            politicalWindows: filters.politicalWindows,
            rateCardTab,
            metricTypes: metrics ? metrics : undefined,
            dayparts: filters.dayparts?.length ? filters.dayparts : undefined,
            spotTypes: filters.spotTypes?.length ? filters.spotTypes : undefined,
            dows: filters.dows?.length ? filters.dows : undefined,
            eventType: filters.eventType?.length ? filters.eventType : undefined,
            p256dh: subscriptionData?.p256dh,
            auth: subscriptionData?.auth,
            endpoint: subscriptionData?.endpoint,
        };
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }
        appendSegmentationRequestParams(filters, body, this.authSvc.getConfig().channelSegmentation[filters.channel]?.rate_card);
        return this.http.post<{ success: boolean }>(
            UrlStore.api.rateCard.asyncLoadPoliticalRateCardV2,
            body,
        );
    }

    filterTriggerRecalc(data: RateCardMetricOverrideV2[]): RateCardMetricOverrideV2[] {
        return data.filter(obj => obj.isOverrideTriggerRecalc);
    }

    createOverridesV2(
        saveOverrides: RateCardMetricOverrideV2[],
        deleteOverrides: RateCardMetricOverrideV2[],
        approveOverrides: RateCardMetricOverrideV2[],
        filter: DynamicRateCardFilter,
        channelId: number,
        rateCardTab: number,
        metricsData: RateCardMetricData = {},
    ): Observable<RateCardMetricRowV2[]> {

        const metricTypes: string[] = [];

        Object.keys(metricsData).forEach(key => {
            if (hasProperty(metricsData, key) && metricsData[key].display) {
                metricTypes.push(metricsData[key].id);
            }
        });

        const queryString = `?startDate=${encodeURIComponent(filter.startDate)}` +
            `&endDate=${encodeURIComponent(filter.endDate)}` +
            `&period=${filter.period.id}` +
            `&channel=${channelId}` +
            `&channels=${filter.channels.join(',')}` +
            `&rateCardTab=${rateCardTab}` +
            `&metricTypes=${metricTypes.join(',')}`;

        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.overridesV2.concat(queryString),
            {
                saveOverrides,
                deleteOverrides,
                approveOverrides,
            },
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    ).pipe(switchMap(data => of(data.data)))),
            );
    }

    createPoliticalOverridesV2(
        saveOverrides: RateCardMetricOverrideV2[],
        deleteOverrides: RateCardMetricOverrideV2[],
        approveOverrides: RateCardMetricOverrideV2[],
        filter: PoliticalRateCardFilter,
        channelId: number,
        rateCardTab: number,
    ): Observable<RateCardMetricRowV2[]> {
        const queryString =
            `?startDate=${encodeURIComponent(filter.startDate)}` +
            `&endDate=${encodeURIComponent(filter.endDate)}` +
            `&period=${filter.period.id}` +
            `&channel=${channelId}` +
            `&channels=${filter.channels.join(',')}` +
            `&politicalWindows=${filter.politicalWindows.join(',')}` +
            `&rateCardTab=${rateCardTab}`;
        const url = UrlStore.api.rateCard.politicalOverridesV2.concat(queryString);
        return this.http.post<PoliticalRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(url, {
            saveOverrides,
            deleteOverrides,
            approveOverrides,
        }).pipe(
            switchMap((response: PoliticalRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                calculateRateCardAlerts2(
                    response.data,
                    response.alerts,
                    response.groupedColumns,
                    undefined,
                    undefined,
                    response.tiers,
                    politicalRateCardCopyCell,
                ).pipe(switchMap(data => of(data.data)))),
        );
    }

    createDigitalOverridesV2(
        saveOverrides: RateCardMetricOverrideV2[],
        deleteOverrides: RateCardMetricOverrideV2[],
        approveOverrides: RateCardMetricOverrideV2[],
        filter: DynamicRateCardFilter,
        channelId: number,
        rateCardTab: number,
    ): Observable<RateCardMetricRowV2[]> {
        const queryString = `?startDate=${encodeURIComponent(filter.startDate)}` +
            `&endDate=${encodeURIComponent(filter.endDate)}` +
            `&period=${filter.period.id}` +
            `&channel=${channelId}` +
            `&channels=${filter.channels.join(',')}` +
            `&rateCardTab=${rateCardTab}`;
        const url = UrlStore.api.rateCard.digitalOverridesV2.concat(queryString);
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(url, {
            saveOverrides,
            deleteOverrides,
            approveOverrides,
        }).pipe(
            switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                calculateRateCardAlerts2(
                    response.data,
                    response.alerts,
                    response.groupedColumns,
                    response.primaryTierMap,
                    undefined,
                    response.tiers,
                    digitalRateCardCopyCell,
                )
                    .pipe(switchMap(data => of(data.data)))),
        );
    }

    createQuarterlyRateCard(channels: number[], tierId: number, quarters: Quarter[], fromQuarter: string = '', adjustment: number = 0, isCopy: boolean = false, initializationApproach: string) {
        // This body can be modified for additional modes of quarterly rate card initialization
        const body = { channels, tierId, quarters, fromQuarter, adjustment, isCopy, initializationApproach };
        return this.http.post(
            UrlStore.api.rateCard.createQuarterlyRateCard,
            body,
        );
    }

    initializePoliticalRateCard(body: { [key: string]: unknown }) {
        return this.http.post(
            UrlStore.api.rateCard.initializePoliticalRateCard,
            body,
        );
    }

    getMetricAlerts(includeDRCAlerts: boolean, includePRCAlerts: boolean, rateCardTab: number = null): Observable<{
        items: RateCardMetricAlerts[];
    }> {
        let url = UrlStore.api.rateCard.alerts + `?drc=${includeDRCAlerts}&prc=${includePRCAlerts}`;
        if (rateCardTab) {
            url += `&rateCardTab=${rateCardTab}`;
        }
        return this.http.get<{ items: RateCardApiMetricAlerts[] }>(url).pipe(
            switchMap((alerts: { items: RateCardApiMetricAlerts[] }) => {
                const rateCardAlerts: RateCardMetricAlerts[] = [];
                alerts.items.forEach(alert => {
                    if (Object.keys(alert.metricAlertIds).length === 1) {
                        const metricAlertId = Object.keys(alert.metricAlertIds)[0];
                        if (alert.metricAlertIds[metricAlertId].length > 0) {
                            alert.metricAlertIds[metricAlertId].forEach(threshold => {
                                rateCardAlerts.push({
                                    ...alert,
                                    metricAlertIds: [metricAlertId + threshold],
                                    name: `${alert.name} ${threshold === 0 ? '' : '(' + threshold + '%)'}`,
                                });
                            });
                        } else {
                            rateCardAlerts.push({
                                ...alert,
                                metricAlertIds: [metricAlertId],
                                name: `${alert.name}`,
                            });
                        }
                    } else {
                        const metricAlertIds = Object.keys(alert.metricAlertIds).map(key => key + alert.metricAlertIds[key][0]);
                        rateCardAlerts.push({
                            ...alert,
                            metricAlertIds,
                        });
                    }
                });
                return of({ items: rateCardAlerts });
            }),
        );
    }

    getRateCardMetricTypes(params: { tab: number }, clearCache: boolean = false): Observable<MetricTypeItem[]> {
        return generateCachedGet<MetricTypeItem[]>(
            clearCache,
            UrlStore.api.rateCardLookupsV2.rateCardMetricTypes,
            this.http,
            params,
            this.cacheData,
        );
    }

    getDigitalCardMetricTypes(params: { tab: number }, clearCache: boolean = false): Observable<MetricTypeItem[]> {
        return generateCachedGet<MetricTypeItem[]>(
            clearCache,
            UrlStore.api.rateCardLookupsV2.digitalRateCardMetricTypes,
            this.http,
            params,
            this.cacheData,
        );
    }

    getPoliticalRateCardMetricTypes(params: {
        tab: number;
    }, clearCache: boolean = false): Observable<MetricTypeItem[]> {
        return generateCachedGet<MetricTypeItem[]>(
            clearCache,
            UrlStore.api.rateCardLookupsV2.politicalRateCardMetricTypes,
            this.http,
            params,
            this.cacheData,
        );
    }

    saveDynamicFavorite(metric: GroupedMetricItem, tab: number): Observable<never> {
        return this.http.post<never>(
            UrlStore.api.rateCardLookupsV2.dynamicMetricFavorite,
            {
                metricId: metric.id,
                tab,
                isFavorite: metric.isFavorite,
            },
        );
    }

    savePoliticalFavorite(metric: GroupedMetricItem, tab: number): Observable<never> {
        return this.http.post<never>(
            UrlStore.api.rateCardLookupsV2.politicalMetricFavorite,
            {
                metricId: metric.id,
                tab,
                isFavorite: metric.isFavorite,
            },
        );
    }

    saveDigitalFavorite(metric: GroupedMetricItem, tab: number): Observable<never> {
        return this.http.post<never>(
            UrlStore.api.rateCardLookupsV2.digitalMetricFavorite,
            {
                metricId: metric.id,
                tab,
                isFavorite: metric.isFavorite,
            },
        );
    }

    getEnabledRatecards(): Observable<LookupItem[]> {
        const url = UrlStore.api.rateCardLookupsV2.enabledRatecards;
        return this.http.get<EnabledRatecard[]>(url);
    }

    getPriorityCodeTiers(channels: number[]): Observable<Grid<PriorityCodeTierRow>> {
        return this.http.get<Grid<PriorityCodeTierRow>>(UrlStore.api.admin.priorityCodeTiers
            + `?channels=${channels}`);
    }

    getPriorityCodeQuartersChannelsInitialized(channels: string): Observable<unknown> {
        return this.http.get<Grid<PriorityCodeTierRow>>(UrlStore.api.rateCardLookupsV2.priorityCodeStationsQuartersInitialized
            + `?channels=${channels}`);
    }

    getRoundingRules(clearCache: boolean = false): Observable<RoundingRules> {
        return generateCachedGet<RoundingRules>(
            clearCache,
            UrlStore.api.rateCardLookupsV2.roundingRules,
            this.http,
            undefined,
            this.cacheData,
        );
    }

    getWomsPrograms(channel: LookupItem, clearCache: boolean = false): Observable<RateOpticsProgram[]> {
        if (!this.womsProgramCache[channel.id] || clearCache) {
            this.womsProgramCache[channel.id] = this.http
                .get<RateOpticsProgram[]>(UrlStore.api.woms.programsV2.concat(`?channel=${channel.id}`))
                .pipe(shareReplay(1));
        }

        return this.womsProgramCache[channel.id];
    }

    getSpecialEventsV2(filters: SpecialEventFilter) {
        const body = {
            channelId: filters.channelId,
            startDate: moment(String(filters.startDate)).format('YYYY-MM-DD'),
            endDate: moment(String(filters.endDate)).format('YYYY-MM-DD'),
            dayparts: filters.dayparts,
        };
        appendSegmentationRequestParams(filters, body);
        return this.http.post(UrlStore.api.specialEventsV2.specialEvents, body);
    }

    uploadWomsXml(channel: LookupItem, file: string | ArrayBuffer): Observable<WomsMappings> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/xml',
            }),
        };
        return this.http.post<WomsMappings>(UrlStore.api.woms.uploadV2.concat(`/${channel.id}`), file, httpOptions);
    }

    getLastWomsUpload(channel: LookupItem): Observable<WomsMappings> {
        return this.http.get<WomsMappings>(UrlStore.api.woms.uploadV2.concat(`/${channel.id}`));
    }

    saveWomsMapping(channel: LookupItem, mappings: WomsMapping[]): Observable<unknown> {
        return this.http.put(UrlStore.api.woms.uploadV2.concat(`/${channel.id}`), { mappings });
    }

    getWomsRateCardV2(channel: LookupItem, dateRange: DateRange, tiers: PricingTier[] = null): Observable<WomsExport> {
        let query = `startDate=${encodeURIComponent(dateRange.startDate.toISOString())}&endDate=${encodeURIComponent(dateRange.endDate.toISOString())}`;
        if (tiers) {
            const tiersIds = tiers.map(({ id }) => id);
            query = query.concat(`&tiers=${tiersIds}`);
        }
        return this.http.get<WomsExport>(UrlStore.api.woms.ratesV2.concat(`/${channel.id}?${query}`));
    }

    getRateOpticsTierSettings(channelIds: number[]): Observable<RateOpticsTierSettings[]> {
        return this.http.get<RateOpticsTierSettings[]>(UrlStore.api.rateCard.tierSettings.concat(`?channels=${channelIds.join(',')}`));
    }

    getRateOpticsDigitalTierSettings(channelIds: number[]): Observable<RateOpticsDigitalTierSettings[]> {
        return this.http.get<RateOpticsDigitalTierSettings[]>(UrlStore.api.rateCard.digitalTierSettings.concat(`?channels=${channelIds.join(',')}`));
    }

    getRateOpticsPCodeTierSettings(channelIds: number[]): Observable<RateOpticsPCodeTierSettings[]> {
        return this.http.get<RateOpticsPCodeTierSettings[]>(UrlStore.api.rateCard.pCodeTierSettings.concat(`?channels=${channelIds.join(',')}`));
    }

    addHiddenSegment(rowData: RateCardMetricRowV2, rateCardTab: number,
                     isDynamic: boolean, isPolitical: boolean, isDigital: boolean): Observable<unknown> {

        const hiddenSegmentData = {
            dynamicSegmentation: rowData.segmentationIds,
            rateCardTab,
            channelId: rowData.channelId,
            daypartId: rowData.daypartId,
            spotTypeId: rowData.spotTypeId,
            isDynamic,
            isPolitical,
            isDigital,
        };

        return this.http.post<void>(UrlStore.api.rateCard.hiddenSegments, hiddenSegmentData);
    }

    saveUserSegment(
        saveSegmentData: AddSegmentPopupReturn,
    ): Observable<RateCardMetricRowV2[]> {
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.userSegment, saveSegmentData,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    ).pipe(switchMap(data => of(data.data)))),
            );
    }

    editSegment(
        saveSegmentData: AddSegmentPopupReturn,
    ): Observable<RateCardMetricRowV2[]> {
        return this.http.patch<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCard.userSegment, saveSegmentData,
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    ).pipe(switchMap(data => of(data.data)))),
            );
    }

    getEditableSegment(channelId: number, appMetricValueId: number): Observable<EditableSegment> {
        return this.http.get<EditableSegment>(
            UrlStore.api.rateCard.userSegment
            + '?channelId=' + channelId
            + '&rateCardAppMetricValueId=' + appMetricValueId,
        );
    }

    saveReorderSegments(parentSegment: { baseSegmentationId: number; channelId: number }, segments: {
        baseSegmentationId: number;
        channelId: number;
    }[], putRowBelow: boolean): Observable<never> {
        return this.http.post<never>(UrlStore.api.rateCardLookupsV2.reorderSegments, { parentSegment, segments, putRowBelow });
    }

    bulkUpdateDRAllocations(file: File): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const headers = new Headers();
        headers.append('enctype', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post<void>(UrlStore.api.rateCard.bulkDRAllocations, formData);
    }

    public compareValues(val1: number, comparator: string, val2: number): boolean {
        if (comparator === '<=') {
            return val1 <= val2;
        } else if (comparator === '<') {
            return val1 < val2;
        } else if (comparator === '=') {
            return val1 === val2;
        } else if (comparator === '>') {
            return val1 > val2;
        } else if (comparator === '>=') {
            return val1 >= val2;
        } else if (comparator === '!=') {
            return val1 !== val2;
        }
        return false;
    }
}

import { Component, Input } from '@angular/core';
import { Moment } from 'moment';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateRangeFilter } from '@models/filter-types';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { segmentTrack } from '@shared/helpers/functions/helpers';
import { Router } from '@angular/router';
import { MyDateAdapter } from '../date-select/date-select.component';


export const US_LOCALE = 'en-US';

@Component({
    selector: 'app-date-range-select',
    templateUrl: './date-range-select.component.html',
    styleUrls: ['./date-range-select.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: US_LOCALE },
        { provide: DateAdapter, useClass: MyDateAdapter, deps: [MAT_DATE_LOCALE] },
    ],
    standalone: false,
})
export class DateRangeSelectComponent {

    @Input() startDate: Moment;
    @Input() endDate: Moment;

    @Input() icon: string;
    @Input() disabled = true;
    @Input() property: DateRangeFilter;

    constructor(
        private router: Router,
    ) {
    }

    startDateChanged(value: MatDatepickerInputEvent<Moment>) {
        this.property.Value = { startDate: value.value, endDate: this.property.Value?.endDate };
    }

    endDateChanged(value: MatDatepickerInputEvent<Moment>) {
        this.property.Value = { startDate: this.property.Value?.startDate, endDate: value.value };
    }

    closed(): void {
        segmentTrack(
            'User Selected From Dropdown',
            {
                dropdown: this.property.Key,
                selection: this.property.transform(this.property.Value),
                screen: this.router.url,
            },
        );
    }
}

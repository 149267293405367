import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Filter, FilterTemplate } from '@models/filter-types';
import { getFilters } from '@shared/helpers/functions/filter-helpers';

@Component({
    selector: 'app-add-segment-popup-filter-row',
    templateUrl: './add-segment-popup-filter-row.component.html',
    styleUrls: ['./add-segment-popup-filter-row.component.scss'],
    standalone: false,
})
export class AddSegmentPopupFilterRowComponent implements OnInit, OnChanges {
    @Input() template: FilterTemplate;
    @Input() dynamicFilters: { [filterName: string]: Filter<unknown> };
    @Input() name = '';
    @Input() isFiltering = false;
    @Input() isRecurring? =false;

    @Output() templateValueChanged = new EventEmitter();

    @Output() filtersLoaded = new EventEmitter();

    @Output() filtersReset = new EventEmitter();

    @Output() optionDeleteRequested: EventEmitter<unknown> = new EventEmitter<unknown>();
    properties: unknown;
    dynamicProperties: Filter<unknown>[];

    isLoaded = false;

    constructor() {
    }

    ngOnInit(): void {
        this.properties = getFilters(this.template).subscribe(filters => {
            this.properties = filters;
            this.properties = Object.keys(filters)
                .filter(k => filters[k] instanceof Filter)
                .map(k => filters[k]);
            this.isLoaded=true;
        });
    }

    ngOnChanges() {
        if (this.dynamicFilters) {
            this.dynamicProperties = Object.keys(this.dynamicFilters)
                .filter(k => this.dynamicFilters[k] instanceof Filter)
                .map(k => this.dynamicFilters[k]);
        }
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { StationExceptionRow } from '@models/lookup';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { AgGridApiDirective } from '@shared/helpers/ag-grid/ag-grid';
import { ColDef } from 'ag-grid-community';
import { Grid } from '@models/lookup';
import { getColumnDefs } from '@shared/helpers/ag-grid/ag-grid-builder';
import { CustomCellRenderers } from '@models/ag-grid';
import { NotificationService } from '@services/notification/notification.service';

@Component({
    selector: 'app-station-exceptions-popup',
    templateUrl: './station-exceptions-popup.component.html',
    styleUrls: ['./station-exceptions-popup.component.scss'],
    standalone: false,
})
export class StationExceptionsPopupComponent extends AgGridApiDirective<StationExceptionRow> implements OnInit {
    loading = true;
    rows: StationExceptionRow[] = [];
    colDefs: ColDef[] = [];

    cellRenderers: CustomCellRenderers = {};

    constructor(
    public dialogRef: MatDialogRef<StationExceptionsPopupComponent>,
    private lookupSvc: LookupV2Service,
    private snackBarSvc: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: unknown,
    ) {
        super();
    }

    ngOnInit(): void {
        this.lookupSvc.getStationExceptions().subscribe((grid: Grid<StationExceptionRow>) => {
            this.loading = false;
            this.colDefs = getColumnDefs(grid.columns, this.cellRenderers);
            this.rows = grid.data;
        },
                                                        () => {
                                                            this.snackBarSvc.openSnackBar('Failed to show station exceptions', 'error');
                                                            this.loading = false;
                                                        });
    }

    closeWindow(): void {
        this.dialogRef.close();
    }

}

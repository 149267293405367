import { Pipe, PipeTransform } from '@angular/core';
import { hasProperty } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'searchFilter',
    standalone: false,
})
export class SearchFilter implements PipeTransform {
    public transform<T extends object>(items: T[], searchText: string, itemField: string): T[] {
        if (searchText === null || searchText === '' || items === null) {
            return items;
        }
        return items.filter(item =>
            hasProperty(item, itemField)
            && item[itemField].toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { canMatch as authCanMatch } from 'src/app/core/guards/auth/auth.guard';
import { TokenInterceptor } from '@services/token.interceptor';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { SharedModule } from '@shared/shared.module';
import { canMatch as rateCardCanMatch } from './core/guards/rate-card/rate-card.guard';
import { canMatch as mainCanMatch } from './core/guards/main/main.guard';
import { canMatch as adminCanMatch } from './core/guards/admin/admin.guard';
import { AccessDeniedComponent } from './access-denied/access-denied/access-denied.component';
import { PushNotificationService } from '@services/push-notification/push-notification.service';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';

const routes: Routes = [

    {
        pathMatch: 'full',
        path: '',
        canMatch: [authCanMatch, mainCanMatch],
        loadChildren: () =>
            import('./main/main.module').then(mod => mod.MainModule),
    },
    {
        path: UrlStore.ui.login,
        loadChildren: () =>
            import('./login/login.module').then(mod => mod.LoginModule),
    },
    {
        path: UrlStore.ui.rateOptics.base,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/rate-card/rate-card.module')
                .then(mod => mod.RateCardModule),
    },
    {
        path: UrlStore.ui.rateOptics.rateCard,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/rate-card/rate-card.module')
                .then(mod => mod.RateCardModule),
    },
    {
        path: UrlStore.ui.rateOptics.rateCardV2,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/rate-card/rate-card.module')
                .then(mod => mod.RateCardModule),
    },
    {
        path: UrlStore.ui.rateOptics.ratePerformance,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/rate-performance/rate-performance.module')
                .then(mod => mod.RatePerformanceModule),
    },
    {
        path: UrlStore.ui.admin.userActivityReport,
        canMatch: [authCanMatch, adminCanMatch],
        loadChildren: () =>
            import('./rate-optics/user-activity-report/user-activity-report.module')
                .then(mod => mod.UserActivityReportModule),
    },
    {
        path: UrlStore.ui.rateOptics.dashboard,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/dashboard/dashboard.module')
                .then(mod => mod.DashboardModule),
    },
    {
        path: UrlStore.ui.rateOptics.politicalLUR,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/political-lur/political-lur.module')
                .then(mod => mod.PoliticalLurModule),
    },
    {
        path: UrlStore.ui.rateOptics.rateGenerator,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/rate-gen/rate-gen.module')
                .then(mod => mod.RateGenModule),
    },
    {
        path: UrlStore.ui.rateOptics.advertiserInsights,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/advertiser-insights/advertiser-insights.module')
                .then(mod => mod.AdvertiserInsightsModule),
    },
    {
        path: UrlStore.ui.rateOptics.inventoryInsights,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/inventory-insights/inventory-insights.module')
                .then(mod => mod.InventoryInsightsModule),
    },
    {
        path: UrlStore.ui.rateOptics.bulkRates,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/bulk-rates/bulk-rates.module')
                .then(mod => mod.BulkRatesModule),
    },
    {
        path: UrlStore.ui.rateOptics.aida,
        canMatch: [authCanMatch, rateCardCanMatch],
        loadChildren: () =>
            import('./rate-optics/aida/aida.module')
                .then(mod => mod.AidaModule),
    },
    {
        path: UrlStore.ui.admin.base,
        canMatch: [adminCanMatch],
        loadChildren: () =>
            import('./admin/admin.module').then(mod => mod.AdminModule),
    },
    {
        path: UrlStore.ui.accessDenied,
        canMatch: [authCanMatch],
        component: AccessDeniedComponent,
        loadChildren: () =>
            import('./access-denied/access-denied.module').then(mod => mod.AccessDeniedModule),
    },
    {
        path: UrlStore.ui.documentation,
        canMatch: [authCanMatch],
        loadChildren: () =>
            import('./oas-documentation/oas-documentation.module').then(mod => mod.OasDocumentationComponentModule),
    },
    // This route must always be the last route in this list
    {
        path: '',
        canMatch: [authCanMatch],
        loadChildren: () =>
            import('./not-found/not-found.module').then(mod => mod.NotFoundModule),
    },
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        HttpClientModule,
        SharedModule,
        RouterModule.forRoot(routes, {}),
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    exports: [
        RouterModule,
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        PushNotificationService,
        SwPush,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}

<!-- <app-filter-input [property]="useMultipliersFilter"></app-filter-input> --> <!--Uncomment whenever choosing to use multipliers is re-enabled-->
<div class="multiplier-container">
    <div class="tier-multiplier-message">
        The multipliers set in the table below are calculated from your primary tier, selected on the
    <br>previous screen. For example, enter “2” to set a tier at twice the value of the primary tier.
    <br> Multipliers can be consistent across dayparts using the “All Dayparts” row. They can also be set at the daypart level, if desired.
    <br><br>Primary Tier: <b>{{primaryTierFilter.Value.name}}</b>
    <br>RateOptics Tier Used: <b>{{tierUsed.Value?.displayName}}</b>
    </div>
    <ag-grid-angular class="tier-multiplier-grid ag-theme-material"
        [rowData]="daypartTierRows"
        [columnDefs]="colDefs"
        (gridReady)="onGridReadySizeToFit($event)"
        (gridColumnsChanged)="gridApi?.sizeColumnsToFit()"
        [suppressColumnVirtualisation]="true"
        [rowBuffer]="0"
        [suppressContextMenu]="true"
        [stopEditingWhenCellsLoseFocus]="true"
        [singleClickEdit]="true"
        [domLayout]="'autoHeight'"
        (cellEditingStopped)="multiplierCascade($event)">
    </ag-grid-angular>
</div>

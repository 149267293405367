import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SegmentOneTimeFilters } from '@models/rate-card/segment-one-time-filters';
import { SegmentRecurringFilters } from '@models/rate-card/segment-recurring-filters';
import {
    AddSegmentPopupReturn,
    EditableSegment,
    NewDynamicSegments,
    NewSegmentData, RateCardMetricRowV2,
    SegmentPopupProps,
} from '@models/rate-card/rate-card';
import moment from 'moment';
import { FilterTemplate, SelectFilter, TimeFilter } from '@models/filter-types';
import { SegmentItem } from '@models/lookup';
import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { NotificationService } from '@services/notification/notification.service';
import { Segmentation, UiConfig } from '@models/config';
import { MessageDialogComponent } from '@shared/components/message-dialog/message-dialog.component';
import { RateCardService } from '@services/rate-card/rate-card.service';

@Component({
    template: '',
    standalone: false,
})
export abstract class SegmentBaseComponent {
    @Input() rowData: SegmentPopupProps;
    @Input() segmentData: EditableSegment = null;
    @Input() dialogRef: MatDialogRef<unknown>;
    @Input() isOneTimeSegment: boolean;
    config: UiConfig;


    constructor(
        protected authSvc: AuthService,
        protected lookupSvc: LookupV2Service,
        protected snackBarSvc: NotificationService,
        protected rateCardSvc: RateCardService,
    ) {
        this.config = this.authSvc.getConfig();
    }

    generateDynamicSegmentValues(filter: SegmentOneTimeFilters | SegmentRecurringFilters) {
        const dynamicSegments: NewDynamicSegments[] = [];

        let groupSegmentation = this.config.groupSegmentation[this.rowData.channelGroupId]?.rate_card || [];
        const timeSegmentation = groupSegmentation.filter(x => x.is_start_time || x.is_end_time);
        const dowSegmentation = groupSegmentation.filter(x => x.is_dow);
        groupSegmentation = groupSegmentation.filter(x => !(x.is_dow || x.is_start_time || x.is_end_time));

        for (const segment of groupSegmentation) {
            const dynamicSegment = this.generateNewStringDynamicSegment(segment, filter);
            dynamicSegments.push(dynamicSegment);
        }
        for (const segment of timeSegmentation) {
            const dynamicSegment = this.generateNewTimeDynamicSegment(segment, filter);
            dynamicSegments.push(dynamicSegment);
        }
        for (const segment of dowSegmentation) {
            const dynamicSegment = this.generateNewDowDynamicSegment(segment, filter);
            dynamicSegments.push(dynamicSegment);
        }

        return dynamicSegments;
    }

    generateNewTimeDynamicSegment(segment: Segmentation, filter: FilterTemplate) {
        const dynamicSegment: NewDynamicSegments = {
            segmentType: segment.segment_type,
            segmentValue: null,
            startTime: moment((filter.dynamicFilters.startTimeFilter as TimeFilter).Value).format('hh:mm A'),
            endTime: moment((filter.dynamicFilters.endTimeFilter as TimeFilter).Value).format('hh:mm A'),
        };
        dynamicSegment.segmentValue = dynamicSegment.startTime + ' - ' + dynamicSegment.endTime;
        return dynamicSegment;
    }

    generateNewStringDynamicSegment(segment: Segmentation, filter) {
        const segmentFilterValue = filter.dynamicFilters[(segment.segment_type)].Value;
        let dynamicSegment: NewDynamicSegments = {
            segmentType: segment.segment_type,
            segmentValue: filter.dynamicFilters[(segment.segment_type)].transform(segmentFilterValue),

        };
        const segmentIds = (filter.dynamicFilters[segment.segment_type] as SelectFilter<SegmentItem>)?.options.find(seg =>
            seg.name === dynamicSegment.segmentValue)?.ids;
        if (segmentIds) {
            dynamicSegment = { ...dynamicSegment, ...{ segmentId: segmentIds.find(id => id > 0) }};
        }
        return dynamicSegment;
    }

    saveAndCloseAddSegmentPopup(newRowData: NewSegmentData[]) {
        const rowSaveData = {
            rateCardTab: this.rowData.rateCardTab,
            isDynamic: this.rowData.isDynamic,
            isPolitical: this.rowData.isPolitical,
            channelId: this.rowData.channelId,
            spotTypeId: this.rowData.spotTypeId,
        };

        const parentSegmentData = {
            daypartId: this.segmentData ? this.segmentData.parentSegmentData.daypartId : this.rowData.daypartId,
            dynamicSegmentation: this.segmentData
                ? this.segmentData.parentSegmentData.dynamicSegmentation
                : this.rowData.parentDynamicSegmentation,
        };

        const body: AddSegmentPopupReturn = {
            reloadParams: this.rowData.reloadParams,
            ...rowSaveData,
            parentSegmentData,
            newSegments: newRowData ,
        };
        const textDialogRef = this.rowData.matDialog.open(MessageDialogComponent, {
            disableClose: true,
            data: {
                message: 'Please wait while this program is added to your Rate Card',
            },
        });
        const saveFunc = this.segmentData
            ? (args: AddSegmentPopupReturn) => this.rateCardSvc.editSegment(args)
            : (args: AddSegmentPopupReturn) => this.rateCardSvc.saveUserSegment(args);
        const onSaveText = this.segmentData ? 'edited' : 'created';
        const onFailText = this.segmentData ? 'edit' : 'create';
        saveFunc(body).subscribe(
            (rows: RateCardMetricRowV2[]) => {
                this.rowData.getRateCardSegments();
                this.snackBarSvc.openSnackBar(`Row successfully ${onSaveText}`, 'success');
                this.rowData.afterSave(rows);
                textDialogRef.close();
                this.dialogRef.close();
            },
            error => {
                this.snackBarSvc.openSnackBar(`Failed to ${onFailText} row`, 'error');
                textDialogRef.close();
            },
        );
    }

    close() {
        this.dialogRef.close();
    }

    abstract generateNewDowDynamicSegment(segment: Segmentation, filter: SegmentOneTimeFilters | SegmentRecurringFilters);


}

<div class="is-flex align-center" [style.width.px]="params.eGridCell.clientWidth" [style.height.px]="params.eGridCell.clientHeight">
    <app-filter-input class="{{ !revertable ? 'text-input' : 'text-input-short' }}" [property]="filter" (filterRef)="saveFilterRef($event)" [preventTab]="true"></app-filter-input>
    <a [class]="'button is-small is-link grid-button'"
    [title]="params.revertTitle || 'Revert Value'"
    *ngIf="revertable"
    (click)="revertCellValue()"
    >
        <i class="fas fa-rotate-left"></i>
    </a>
    <a [class]="'button is-small is-danger grid-button'"
       [title]="params.clearFilterTitle || 'Reset Cell'"
       *ngIf="clearable"
       (click)="clearFilter()"
    >
        <i class="fas fa-times"></i>
    </a>
</div>

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-button-cell',
    templateUrl: './button-cell.component.html',
    styleUrls: ['./button-cell.component.scss'],
    standalone: false,
})
export class ButtonCellComponent implements ICellRendererAngularComp {
    url: string;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.url = params.value;
    }

    refresh() {
        return true;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { bigMoneyFormatter } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'bigMoney',
    standalone: false,
})
export class BigMoneyPipe implements PipeTransform {

    transform(input: number, formatString: string = '1.2-2', pipeType: string = 'currency'): string {
        return bigMoneyFormatter(input, formatString, pipeType);

    }

}

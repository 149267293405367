import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MultiSelectFilter } from '@models/filter-types';
import { LookupItem } from '@models/lookup';
import { isSingleOptionSelected } from '@shared/helpers/functions/helpers';
import { Observable } from 'rxjs';
import { SelectFilterCellParams } from '@models/ag-grid';

@Component({
    selector: 'app-multi-select-filter-cell',
    templateUrl: './multi-select-filter-cell.component.html',
    styleUrls: ['./multi-select-filter-cell.component.scss'],
    standalone: false,
})
export class MultiSelectFilterCellComponent implements ICellRendererAngularComp {

    public params: SelectFilterCellParams;

    filter: MultiSelectFilter<LookupItem>;

    constructor() {
    }

    agInit(params: SelectFilterCellParams): void {
        this.params = params;
        if (!this.params.additionalFilterParams) {
            this.params.additionalFilterParams = [];
        }
        this.filter = new MultiSelectFilter<LookupItem>('')
            .Defer(() => this.getFilterOptions())
            .OnLoad(() => {
                const cellValue = params.node.data[params.colDef.field] as unknown[];
                this.filter.Default(cellValue || []);
                if (this.filter.Value) {
                    params.node.data[params.colDef.field] = this.filter.Value;
                }
            })
            .CompareFn((l, r) => isSingleOptionSelected(l, r))
            .OnChange((items: LookupItem[]) => {
                params.node.data[params.colDef.field] = items;
                if (this.params.changeFunction) {
                    this.params.changeFunction(params.node.data, params.node, params.api, this.filter);
                }
            })
            .Filterable(this.params.filterable)
            .DisplayKey(false)
            .Validator(value => !this.params.required || this.params.required && value && value.length !== 0, () => '')
            .Readonly(!this.params.isEditable);

        this.filter.Load().subscribe((data: LookupItem[]) => this.filter.finishLoad(data));
    }

    refresh() {
        return true;
    }

    getFilterOptions(): Observable<LookupItem[]> {
        this.filter.Readonly(!this.params.isEditable);
        return this.params.filterSource(
            ...this.params.additionalFilterParams,
            this.params.node.data.id,
        );
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { SegmentOneTimeFilters } from '@models/rate-card/segment-one-time-filters';
import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { NewDynamicSegments, NewSegmentData } from '@models/rate-card/rate-card';
import { validateFilters } from '@shared/helpers/functions/filter-helpers';
import moment from 'moment';
import { NotificationService } from '@services/notification/notification.service';
import { SegmentBaseComponent } from '../segment-base.component';
import { RateCardService } from '@services/rate-card/rate-card.service';
import { Segmentation } from '@models/config';
import { cloneDeep } from 'lodash';
import { TimeFilter } from '@models/filter-types';

@Component({
    selector: 'app-one-time-segment',
    templateUrl: './one-time-segment.component.html',
    styleUrls: ['./one-time-segment.component.scss'],
    standalone: false,
})
export class OneTimeSegmentComponent extends SegmentBaseComponent implements OnInit {
    @Input() canAddRow = true;
    @Input() hideRate = false;

    oneTimeFilters: SegmentOneTimeFilters[];

    constructor(
        protected authSvc: AuthService,
        protected lookupSvc: LookupV2Service,
        protected snackBarSvc: NotificationService,
        protected rateCardSvc: RateCardService,
    ) {
        super(authSvc, lookupSvc, snackBarSvc, rateCardSvc);
    }

    ngOnInit(): void {
        this.oneTimeFilters = [new SegmentOneTimeFilters(
            this.lookupSvc,
            this.config,
            this.rowData.rateCardTab,
            this.rowData.channelGroupId,
            this.rowData.channelId,
            this.rowData.spotTypeId,
            this.rowData.daypartId,
            this.rowData.priceFloor,
            this.rowData.priceMax,
            this.rowData.primarySpotLength,
            this.rowData.primaryTier,
            this.segmentData,
            this.hideRate,
        )];
    }

    addRowOneTime(): void {
        if (this.canAddRow) {
            const existingFilter=this.oneTimeFilters[this.oneTimeFilters.length-1];
            const newFilter=new SegmentOneTimeFilters(
                this.lookupSvc,
                this.config,
                this.rowData.rateCardTab,
                this.rowData.channelGroupId,
                this.rowData.channelId,
                this.rowData.spotTypeId,
                this.rowData.daypartId,
                this.rowData.priceFloor,
                this.rowData.priceMax,
                this.rowData.primarySpotLength,
                this.rowData.primaryTier,
                this.segmentData,
                this.hideRate,
            );
            newFilter.oneTimeDate.setValueNoOnChange(cloneDeep(existingFilter.oneTimeDate.Value));
            newFilter.rateFilter.setValueNoOnChange(cloneDeep(existingFilter.rateFilter.Value));
            if(existingFilter.dynamicFilters.startTimeFilter){
                (newFilter.dynamicFilters.startTimeFilter as TimeFilter)
                    .setValueNoOnChange(cloneDeep(existingFilter.dynamicFilters.startTimeFilter.Value as moment.Moment));
            }
            if(existingFilter.dynamicFilters.endTimeFilter){
                (newFilter.dynamicFilters.endTimeFilter as TimeFilter)
                    .setValueNoOnChange(cloneDeep(existingFilter.dynamicFilters.endTimeFilter.Value as moment.Moment));
            }
            this.oneTimeFilters.push(newFilter);
        }
    }

    save() {
        const newRowData: NewSegmentData[]= []; // Update when filters are added
        if (validateFilters( this.oneTimeFilters).isValid) {
            for (const filter of this.oneTimeFilters) {
                const dynamicSegments: NewDynamicSegments[] = this.generateDynamicSegmentValues(filter);

                const filterRowData: NewSegmentData = {
                    daypartId: filter.dayparts.Value.id,
                    rate: filter.rateFilter.Value,
                    isOneTimeSegment: true,
                    dynamicSegments,
                    ...(this.segmentData?.requestId ? { requestId: this.segmentData.requestId } : {}),
                };

                filterRowData.oneTimeSegment = {
                    date: moment(filter.oneTimeDate.Value).format('YYYY-MM-DD'),
                };
                newRowData.push(filterRowData);
            }
            this.saveAndCloseAddSegmentPopup(newRowData);
        } else {
            this.snackBarSvc.openSnackBar('At least one filter is invalid', 'error');
        }
    }

    generateNewDowDynamicSegment(segment: Segmentation, filter: SegmentOneTimeFilters) {
        let segmentValue: string;
        this.lookupSvc.getSegmentDOW().subscribe(dowsFilter => {
            const dow = moment(filter.oneTimeDate.Value).format('ddd');
            segmentValue = dowsFilter.find(day => day.name = dow).name;
        });
        const dynamicSegment: NewDynamicSegments = {
            segmentType: segment.segment_type,
            segmentValue,
        };
        return dynamicSegment;
    }


}

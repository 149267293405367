import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DeferrableFilter, PoliticalWindowConfigTiersFilter } from '@models/filter-types';
import { LookupItem } from '@models/lookup';
import { ICellRendererParams } from 'ag-grid-community';
import { isMultiOptionSelected, isSingleOptionSelected } from '@shared/helpers/functions/helpers';
import { of, Observable } from 'rxjs';
import { SelectPoliticalPopupFilterCellParams } from '@models/ag-grid';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-select-filter-cell',
    templateUrl: './select-filter-cell.component.html',
    styleUrls: ['./select-filter-cell.component.scss'],
    standalone: false,
})
export class SelectFilterCellComponent implements ICellRendererAngularComp {

    public params: SelectPoliticalPopupFilterCellParams;

    filter: DeferrableFilter<LookupItem, LookupItem>;

    filterRef: MatSelect;

    constructor() {
    }

    agInit(params: SelectPoliticalPopupFilterCellParams): void {
        this.params = params;
        if (!this.params.additionalFilterParams) {
            this.params.additionalFilterParams = [];
        }
        this.filter = new PoliticalWindowConfigTiersFilter<LookupItem>('Select')
            .Defer(() => this.getFilterOptions())
            .OnLoad((items: LookupItem[]) => {
                if (params.node.data[params.colDef.field] && !isMultiOptionSelected(params.node.data[params.colDef.field], items)) {
                    this.filter.Options([...this.filter.options, params.node.data[params.colDef.field]]);
                }
                this.filter.Default(params.node.data[params.colDef.field]);
                if (this.filter.Value) {
                    params.node.data[params.colDef.field] = this.filter.Value;
                }
            })
            .CompareFn((l, r) => isSingleOptionSelected(l, r))
            .OnChange((item: LookupItem) => {
                if (!isSingleOptionSelected(item, params.node.data[params.colDef.field])) {
                    params.node.data[params.colDef.field] = item;
                    if (this.params.firstCascadeChild) {
                        this.params.node.data[this.params.firstCascadeChild] = null;
                        this.params.api.refreshCells({
                            rowNodes: [this.params.node],
                            columns: [this.params.firstCascadeChild],
                            force: true,
                        });
                    }
                }
                if (this.params.changeFunction) {
                    this.params.changeFunction(params.node.data, params.node, params.api, this.filter);
                }
            })
            .Filterable(this.params.filterable)
            .DisplayKey(false)
            .Validator((v: LookupItem) => !this.params.required || v && this.params.required, () => '')
            .Readonly(!this.params.isEditable)
            .IsClearable(this.params.clearable)
            .ShowTierGroup(this.params.showTierGroup)
            .ShowPcodes(this.params.showPcodes);

        this.filter.Load().subscribe((data: LookupItem[]) => this.filter.finishLoad(data));
    }

    saveFilterRef(event: MatSelect): void {
        this.filterRef = event;
    }

    refresh(input: ICellRendererParams) {
        if (input.data[input.colDef.field] === null || input.data[input.colDef.field]?.id !== this.filter.Value?.id) {
            let oldFilterValue;
            if (input.data[input.colDef.field] === null) {
                oldFilterValue = this.filter.Value;
            } else {
                oldFilterValue = null;
            }
            const oldCellValue = input.data[input.colDef.field];
            if (oldCellValue && oldCellValue?.id !== oldFilterValue?.id) {
                input.node.data[input.colDef.field] = null;
                this.filter.Options([...this.filter.options, oldCellValue]);
                this.filter.Default(this.filter.options.find(option => isSingleOptionSelected(option, oldCellValue)) || null);
            } else if (this.params.firstCascadeParent) {
                if (this.params.firstCascadeParent === 'channel' && this.params.node.data.channel) {
                    this.params.node.data.customDaypartsAllowed = this.params.node.data.channel.customDaypartsAllowed;
                }
                this.getFilterOptions().subscribe(options => {
                    this.filter.Options(options);
                    this.filter.Value = this.filter.options.find(option =>
                        isSingleOptionSelected(option, oldFilterValue ? oldFilterValue : this.filter.Value));
                    if (this.filter.options.length === 1) {
                        this.filter.Value = this.filter.options[0];
                    }
                });
            }
        }

        return true;
    }

    getFilterOptions(): Observable<LookupItem[]> {
        if (this.params.firstCascadeParent) {
            if (this.params.secondCascadeParent) {
                if (this.params.thirdCascadeParent) {
                    if (this.params.node.data[this.params.firstCascadeParent]
                        && this.params.node.data[this.params.secondCascadeParent]
                        && this.params.node.data[this.params.thirdCascadeParent]) {
                        this.filter.Readonly(!this.params.isEditable);
                        return this.params.filterSource(
                            this.params.node.data[this.params.thirdCascadeParent],
                            this.params.node.data[this.params.secondCascadeParent],
                            this.params.node.data[this.params.firstCascadeParent],
                            ...this.params.additionalFilterParams,
                            this.params.node.data.id,
                        );
                    } else {
                        this.filter.Readonly(true);
                        return of([]);
                    }
                } else {
                    if (this.params.node.data[this.params.firstCascadeParent] && this.params.node.data[this.params.secondCascadeParent]) {
                        this.filter.Readonly(!this.params.isEditable);
                        return this.params.filterSource(
                            this.params.node.data[this.params.secondCascadeParent],
                            this.params.node.data[this.params.firstCascadeParent],
                            ...this.params.additionalFilterParams,
                            this.params.node.data.id,
                        );
                    } else {
                        this.filter.Readonly(true);
                        return of([]);
                    }
                }
            } else {
                if (this.params.node.data[this.params.firstCascadeParent]) {
                    this.filter.Readonly(!this.params.isEditable);
                    return this.params.filterSource(
                        this.params.node.data[this.params.firstCascadeParent],
                        ...this.params.additionalFilterParams,
                        this.params.node.data.id,
                    );
                } else {
                    this.filter.Readonly(true);
                    return of([]);
                }
            }
        } else {
            this.filter.Readonly(!this.params.isEditable);
            return this.params.filterSource(
                ...this.params.additionalFilterParams,
                this.params.node.data.id,
            );
        }
    }

}

<div *ngIf="!isLoaded">
    <strong class="mat-toolbar-row margin-top-75-em">
        Loading Filters...
    </strong>
</div>

<div *ngIf="isLoaded" class="is-flex filter-set-wrapper padding-75-em">
    <div [ngClass]="{'filter-table':isRecurring,'filter-list':!isRecurring}">
        <ng-container *ngFor="let prop of properties">
            <app-filter-input class="fill-space"  [property]="prop" [overrideDisabled]="prop.isReadonly"></app-filter-input>

            <ng-container *ngIf="prop.dynamicFilterParent">
                <ng-container *ngTemplateOutlet="dynamicFilters" ></ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #dynamicFilters>
    <ng-container *ngFor="let dprop of dynamicProperties">
            <app-filter-input class="fill-space" [property]="dprop" [overrideDisabled]="dprop.isReadonly"></app-filter-input>
    </ng-container>
</ng-template>

<div>
    <app-add-segment-popup-filter-row #segmentRecurringFilterSet
                                      [template]="recurringFilters"
                                      [dynamicFilters]="recurringFilters.dynamicFilters"
                                      [isRecurring]="true">
    </app-add-segment-popup-filter-row>
</div>
<div class="level is-full-width button-row button-container">
    <div class="level-left">
    </div>
    <div class="level-right">
        <button class="button level-item is-danger popup-button" (click)="close()">
            Cancel
        </button>
        <button class="button level-item is-primary popup-button" (click)="save()">
            Save
        </button>
    </div>
</div>

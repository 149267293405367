import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-wizard-wrapper',
    templateUrl: './wizard-wrapper.component.html',
    styleUrls: ['./wizard-wrapper.component.scss'],
    standalone: false,
})
export class WizardWrapperComponent implements OnInit {
    @Input() steps: string[];
    @Input() forwardText: string[];
    @Input() forwardValidation: (() => boolean)[];
    @Input() backwardText: string[];
    @Input() title: string;
    @Input() instructions: string;
    @Input() dialogRef: MatDialogRef<unknown>;
    @Input() stepCount = 0;
    @Input() showStepName = true;
    @Input() submitCallback: (() => void);
    @Output() tabChanged: EventEmitter<unknown> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
        this.dialogRef.addPanelClass('wizard-wrapper');
    }

    closeForm(): void {
        this.dialogRef.close();
    }

    submit(): void {
        if (this.stepCount === (this.steps.length - 1)) {
            this.submitCallback();
        }
    }

    goBack() {
        if (this.stepCount > 0) {
            this.tabChanged.emit(-1);
        } else {
            this.dialogRef.close();
        }
    }

    goForward() {
        if (this.forwardValidation[this.stepCount]()) {
            if (this.stepCount !== this.steps.length - 1) {
                this.tabChanged.emit(1);
            } else {
                this.submit();
            }
        }
    }

}

<div *ngIf="isPrimaryRate">
    <a #trigger (click)="onClickHandler()" class="button is-small is-primary">
        <i class="fas fa-pencil-alt"></i>
    </a>

    <div #content *ngIf="isOpen" class="menu-container">
        <!-- enable this when edit works -->
        <!--      <div class="menu-item" *ngIf = "params.showEdit"><span><i class="fas fa-pen-to-square"></i></span>Edit Row</div>-->
        <div class="menu-item" *ngIf="params.showAdd" (click)="openAddSegmentPopup()"><span><i
            class="fas fa-square-plus"></i></span>Add Row Below
        </div>
        <div class="menu-item" *ngIf="params.showEdit" (click)="openEditSegmentPopup()"><span><i
            class="fas fa-pen-to-square"></i></span>Edit Row
        </div>
        <div class="menu-item" *ngIf="params.showRemove" (click)="openHideSegmentDialogForConfirmation()"><span><i
            class="fas fa-circle-xmark"></i></span>Remove Row
        </div>
    </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { NotificationData } from '@models/notification';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    standalone: false,
})
export class NotificationComponent {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
                private snackBar: MatSnackBar) {
    }

    public dismiss(): void {
        this.snackBar.dismiss();
    }
}

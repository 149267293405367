import { Component, OnInit } from '@angular/core';
import { SegmentRecurringFilters } from '@models/rate-card/segment-recurring-filters';
import { AuthService } from '@services/auth/auth.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { validateFilters } from '@shared/helpers/functions/filter-helpers';
import { NewDynamicSegments, NewSegmentData } from '@models/rate-card/rate-card';
import moment from 'moment';
import { NotificationService } from '@services/notification/notification.service';
import { SegmentBaseComponent } from '../segment-base.component';
import { RateCardService } from '@services/rate-card/rate-card.service';
import { Segmentation } from '@models/config';

@Component({
    selector: 'app-recurring-segment',
    templateUrl: './recurring-segment.component.html',
    styleUrls: ['./recurring-segment.component.scss'],
    standalone: false,
})
export class RecurringSegmentComponent extends SegmentBaseComponent implements OnInit {

    recurringFilters: SegmentRecurringFilters;

    constructor(
        protected authSvc: AuthService,
        protected lookupSvc: LookupV2Service,
        protected snackBarSvc: NotificationService,
        protected rateCardSvc: RateCardService,
    ) {
        super(authSvc, lookupSvc, snackBarSvc, rateCardSvc);
    }

    ngOnInit(): void {
        this.recurringFilters = new SegmentRecurringFilters(
            this.lookupSvc,
            this.config,
            this.rowData.rateCardTab,
            this.rowData.channelGroupId,
            this.rowData.channelId,
            this.rowData.spotTypeId,
            this.rowData.daypartId,
            this.rowData.priceFloor,
            this.rowData.priceMax,
            this.rowData.primarySpotLength,
            this.rowData.primaryTier,
            this.segmentData,
        );
    }

    save(): void {
        if ( validateFilters( [this.recurringFilters]).isValid) {
            const dynamicSegments: NewDynamicSegments[] = this.generateDynamicSegmentValues(this.recurringFilters);

            const filterRowData: NewSegmentData = {
                daypartId: this.recurringFilters.dayparts.Value.id,
                rate: this.recurringFilters.rateFilter.Value,
                isOneTimeSegment: false,
                dynamicSegments,
                ...(this.segmentData?.requestId ? { requestId: this.segmentData.requestId } : {}),
            };

            filterRowData.recurringSegment = {
                startDate: moment(this.recurringFilters.startDate.Value).format('YYYY-MM-DD'),
                endDate: moment(this.recurringFilters.endDate.Value).format('YYYY-MM-DD'),
                dows: this.recurringFilters.dow.Value.ids,
                hiatusWeeks: this.recurringFilters.hiatusWeeks.Value,
            };

            this.saveAndCloseAddSegmentPopup([filterRowData]);
        } else {
            this.snackBarSvc.openSnackBar('At least one filter is invalid', 'error');
        }
    }

    generateNewDowDynamicSegment(segment: Segmentation, filter: SegmentRecurringFilters) {
        return {
            segmentType: segment.segment_type,
            segmentValue: filter.dow.Value.name,
        };
    }
}
